module.exports={
	"tile.Handling": "Prise en charge",
	"tile.EndOfRepair": "Fin de réparation",
	"tile.EndOfPurchase": "Vente rachat",
	"tile.Customer": "Client",
	"tile.DeliveryToCustomer": "Retour client",
	"tile.ProductPurchase": "Rachat",
	"tile.RepairInformation": "Information reparation",
	"tile.Dashboard": "Tableau de bord",
	"tile.other": "Autre",

	"admin.Repairs": "Interventions",
	"admin.PurchasesDevices": "Rachats",
	"admin.Employees": "Employés",
	"admin.Customers": "Clients",
	"admin.Models": "Modèles",
	"admin.Actions": "Actions",
	"admin.Parts": "Pièces",
	"admin.Workshops": "Ateliers",
	"admin.Reports": "Reportings (PDF)",
	"admin.CSV": "Reportings (CSV)",
	"admin.Import": "Import",
	"admin.MonthlyRevenue": "Validation du chiffre d'affaires",
	"admin.title": "Administration",
	"admin.Brand": "Marques",
	"admin.DeviceType": "Types de produit",
	"admin.DiscountType": "Remises",
	"admin.IdentityType": "Preuves identité",
	"admin.PaymentType": "Modes de paiement",
	"admin.PurchaseType": "Types de rachat",
	"admin.Status": "Statuts",
	"admin.StockType": "Mouvements de stock",
	"admin.WorkshopGroup": "Groupes",
	"admin.PaymentDelay": "Délais de paiements",
	"admin.Role": "Rôles",
	"admin.Invoice": "Factures",
	"admin.Provider": "Fournisseurs",
	"admin.DecreaseStock": "Mise à jour de stock",
	"admin.Template": "Modèle d'email",

	"header.menu.user.title": "Utilisateur",
	"header.menu.user.profile": "Compte",
	"header.menu.user.logout": "Déconnexion",

	"404.header.text": "Erreur",
	"404.info.top.text": "...",
	"404.info.down.text": "...",
	"404.link.home": "Accueil",

	"breadcrumbs": "Accueil",
	"breadcrumbs.user-profile": "Espace utilisateur",
	"breadcrumbs.repair": "Reparation",
	"breadcrumbs.repair_info": "Information réparation",
	"breadcrumbs.attendance": "Pointage employés",
	"breadcrumbs.models": "Modèles",
	"breadcrumbs.repairs": "Interventions",
	"breadcrumbs.reports": "Reportings",
	"breadcrumbs.dashboard": "Tableau de bord",
	"breadcrumbs.purchase": "Rachat",
	"breadcrumbs.delivery": "Retour client",
	"breadcrumbs.handling": "Prise en charge",
	"breadcrumbs.coming-soon": "En attente...",
	"breadcrumbs.csv": "Reportings csv",
	"breadcrumbs.end_of_purchase": "Vente rachat",
	"breadcrumbs.monthly_revenue": "Chiffre d'affaires HT mensuel",
	"breadcrumbs.purchases": "Rachats",
	"breadcrumbs.employees": "Employés",
	"breadcrumbs.customers": "Clients",
	"breadcrumbs.actions": "Actions",
	"breadcrumbs.workshops": "Ateliers",
	"breadcrumbs.brand": "Marque",
	"breadcrumbs.device_type": "Type de produit",
	"breadcrumbs.discount_type": "Remise",
	"breadcrumbs.identity_type": "Preuve identité",
	"breadcrumbs.payment_type": "Mode de paiement",
	"breadcrumbs.purchase_type": "Type de rachat",
	"breadcrumbs.status": "Statut",
	"breadcrumbs.stock_type": "Mouvement de stock",
	"breadcrumbs.workshop_group": "Groupe",
	"breadcrumbs.payment_delay": "Délai de paiement",
	"breadcrumbs.role": "Rôle",
	"breadcrumbs.invoice": "Facture",
	"breadcrumbs.provider": "Fournisseur",
	"breadcrumbs.decrease_stock": "Mise à jour de stock",
	"breadcrumbs.template": "Modèle d'email",

	"wizard.handling.device": "Type de produit",
	"wizard.handling.brand": "Marque",
	"wizard.handling.model": "Modéle",
	"wizard.handling.customer": "Client",
	"wizard.handling.options": "Options",
	"wizard.handling.advance": "Acompte",
	"wizard.handling.action": "Actions",
	"wizard.handling.validation": "Validation",
	"wizard.delivery.return": "Retour client",
	"wizard.delivery.validation": "Validation",
	"wizard.purchase.device": "Type de produit",
	"wizard.purchase.brand": "Marque",
	"wizard.purchase.model": "Modèle",
	"wizard.purchase.action": "Actions",
	"wizard.purchase.condition": "Etat",
	"wizard.purchase.customer": "client",
	"wizard.purchase.options": "Options",
	"wizard.purchase.tests": "Tests",
	"wizard.purchase.validation": "Validation",
	"wizard.repair.order": "Intervention",
	"wizard.repair.validation": "Validation",
	"wizard.repair.action": "Actions",
	"wizard.dashboard.global": "Global",
	"wizard.dashboard.detail": "Details",
	"wizard.repair_info.information": "Information",
	"wizard.attendance.attendance_inf": "Pointage employés",
	"wizard.models.models_list": "Liste de modèles",
	"wizard.models.new": "Nouveau modèle",
	"wizard.models.edit": "Modification de modèle",
	"wizard.reports.document": "Documents",
	"wizard.reports.csv": "Reportings csv",
	"wizard.repairs.repairs_list": "Liste des Interventions",
	"wizard.repairs.edit": "Modification de réparation",
	"wizard.end_of_purchase.purchase": "Rachat",
	"wizard.end_of_purchase.validation": "Validation",
	"wizard.purchases.purchases_list": "Liste des rachats",
	"wizard.purchases.edit": "Modification d'un rachat",
	"wizard.delivery.discount": "Remise",
	"wizard.delivery.payment": "Paiement",
	"wizard.employees.employees_list": "Liste des employés",
	"wizard.employees.edit": "Modification d'un employé",
	"wizard.employees.new": "Création d'un employé",
	"wizard.customers.customers_list": "Liste des clients",
	"wizard.customers.edit": "Modification d'un client",
	"wizard.customers.new": "Ajout d'un client",
	"wizard.actions.actions_list": "Liste des actions",
	"wizard.actions.edit": "Modification d'une action",
	"wizard.actions.new": "Création d'une action",
	"wizard.workshops.workshops_list": "Liste des ateliers",
	"wizard.workshops.edit": "Modification d'un atelier",
	"wizard.workshops.new": "Création d'un atelier",
	"wizard.brand.list": "Liste des marques",
	"wizard.brand.edit": "Modification d'une marque",
	"wizard.brand.new": "Ajout d'une marque",
	"wizard.device_type.list": "Liste des types de produits",
	"wizard.device_type.edit": "Modification des types de produits",
	"wizard.device_type.new": "Ajout d'un type de produits",
	"wizard.discount_type.list": "Liste des remises",
	"wizard.discount_type.edit": "Modification d'une remise",
	"wizard.discount_type.new": "Ajout d'une remise",
	"wizard.identity_type.list": "Liste des preuves d'identité",
	"wizard.identity_type.edit": "Modification d'une preuve d'identité",
	"wizard.identity_type.new": "Ajout d'une preuve d'identité",
	"wizard.payment_type.list": "Liste des modes de paiements",
	"wizard.payment_type.edit": "Modification d'un mode de paiement",
	"wizard.payment_type.new": "Ajout d'un mode de paiement",
	"wizard.purchase_type.list": "Liste des types de rachat",
	"wizard.purchase_type.edit": "Modification d'un type de rachat",
	"wizard.purchase_type.new": "Ajout d'un type de rachat",
	"wizard.status.list": "Liste des statuts",
	"wizard.status.edit": "Modification d'un statut",
	"wizard.status.new": "Ajout d'un statut",
	"wizard.stock_type.list": "Liste des mouvements de stock",
	"wizard.stock_type.edit": "Modification d'un mouvement de stock",
	"wizard.stock_type.new": "Ajout d'un mouvement de stock",
	"wizard.workshop_group.list": "Liste des groupes d'ateliers",
	"wizard.workshop_group.edit": "Ajout d'un groupe d'atelier",
	"wizard.workshop_group.new": "Modification d'un groupe d'atelier",
	"wizard.payment_delay.list": "Liste des délai de paiement",
	"wizard.payment_delay.edit": "Modification d'un délai de paiement",
	"wizard.payment_delay.new": "Ajout d'un délai de paiement",
	"wizard.role.list": "Liste des rôles",
	"wizard.role.edit": "Modiciation d'un rôle",
	"wizard.role.new": "Ajout d'un rôle",
	"wizard.invoice.list": "Liste des factures",
	"wizard.invoice.edit": "Modification de facture",
	"wizard.invoice.new": "Création de facture",
	"wizard.provider.list": "Liste des Fournisseurs",
	"wizard.provider.edit": "Modification d'un fournisseur",
	"wizard.provider.new": "Ajout d'un fournisseur",
	"wizard.decrease_stock.edit": "Mise à jour de stock",
	"wizard.decrease_stock.actions_list": "Mise à jour de stock",
	"wizard.template.list": "Liste des modèles d'email",
	"wizard.template.edit": "Modification d'un modèle d'email",
	"wizard.template.new": "Ajout d'un modèle d'email",

	"login.button.submit": "Validation",
	"login.input.username.placeholder": "Identifiant",
	"login.input.password.placeholder": "Mot de passe",

	"select-workshop.button.submit": "Validation",
	"select-workshop.h4": "Selectionner un atelier",
	"select-workshop.nodata.text": "Pas d'atelier disponible",
	"select-workshop.nodata.btn": "Déconnexion",
	"select.no-options": "Pas de valeurs",
	"handling.customer.title": "Information client",
	"handling.customer.submit": "Validation",
	"handling.options.title": "Options",
	"handling.options.tempdevice": "Prêt d'un appareil ?",
	"handling.options.accessory": "Présence d'un accessoire ?",
	"handling.options.quote": "Demande de devis ?",
	"handling.options.partorder": "Commande de pièce ?",
	"handling.options.serialnumber": "N°série",
	"handling.options.unlockcode": "Code de déverrouillage",
	"handling.options.comment_ext": "Commentaire...",
	"handling.options.comment_int": "Commentaire int...",
	"handling.options.submit": "Suivant",
	"handling.actions.submit": "Suivant",
	"handling.advance.title": "Acompte",
	"handling.advance.amount": "Montant...",
	"handling.advance.select.card": "Carte bancaire",
	"handling.advance.select.check": "Chèque",
	"handling.advance.select.cash": "Espèces",
	"handling.advance.submit": "Suivant",
	"handling.advance.error": "Le montant de l'acompte ne peut pas être supérieur au montant total",
	"handling.validation.title": "Validation",
	"handling.validation.number": "N°intervention",
	"handling.validation.brandmodel": "Produit",
	"handling.validation.date": "Date de retour prévue",
	"handling.validation.name": "Client",
	"handling.validation.actions": "Actions",
	"handling.validation.amount": "Montant",
	"handling.validation.submit": "Validation",
	"handling.device.nodata.text": "Aucun périphérique trouvé",
	"handling.devicet": "Dispositif",
	"handling.brand.nodata.text": "Aucune marque trouvée",
	"handling.brand": "Marque",
	"handling.model": "Modèle",
	"handling.model.noData": "Aucun modèle trouvé",
	"handling.actions": "Actions",
	"handling.actions.amount": "Montant",
	"handling.actions.nodata.text": "Aucune action trouvée",
	"handling.customers.nodata.text": "Aucun client trouvé",
	"handling.advance.nodata.text": "Aucun type de paiement trouvé",
	"handling.customer.table.name": "Nom",
	"handling.customer.table.phone": "Téléphone",
	"handling.customer.table.city": "Ville",
	"handling.emailError": "E-mail incorrect",

	"tryAgain": "Réessayer",


	"purchase.condition.title": "Etat",
	"purchase.condition.price": "Montant",
	"purchase.condition.discount": "Montant de la décôte",
	"purchase.condition.total": "Total",
	"purchase.condition.submit": "Suivant",
	"purchase.options.title": "Options",
	"purchase.options.serialnumber": "N°Série",
	"purchase.options.unlockcode": "Code de déverrouillage",
	"purchase.options.comment": "Commentaire...",
	"purchase.options.identification": "Référence du justificatif",
	"purchase.options.submit": "Suivant",
	"purchase.tests.title": "Tests",
	"purchase.validation.title": "Validation",
	"purchase.validation.name": "Client",
	"purchase.validation.brandmodel": "Produit",
	"purchase.validation.serialnumber": "N°série",
	"purchase.validation.select.identity": "Carte d'identité",
	"purchase.validation.select.passport": "Passeport",
	"purchase.validation.comment": "Commentaire...",
	"purchase.validation.condition": "Etat",
	"purchase.validation.price": "Montant",
	"purchase.validation.submit": "Validation",
	"purchase.options.modalTitle": "Signature",
	"purchase.options.modalInput": "Saisir la signature",
	"purchase.options.pad.label": "Signature",
	"purchase.options.pad.clear": "Vider",
	"purchase.options.pad.ok": "OK",

	"purchase.diagnostic.1": "Le téléphone s'allume",
	"purchase.diagnostic.2": "Le lecteur d'empreinte fonctionne",
	"purchase.diagnostic.3": "Je peux passer des appels",
	"purchase.diagnostic.4": "La batterie tiens la charge (perte maxi 8% sur 15min)",
	"purchase.diagnostic.5": "La connection wifi fonctionne",
	"purchase.diagnostic.6": "Carte SD fonctionne",
	"purchase.diagnostic.7": "Le téléphone est remis à zéro",
	"purchase.diagnostic.8": "La carte sim est retirée",
	"purchase.diagnostic.9": "...",
	"purchase.diagnostic.10": "...",
	"purchase.diagnostic.11": "...",
	"purchase.diagnostic.12": "...",
	"purchase.diagnostic.13": "...",
	"purchase.diagnostic.14": "...",
	"purchase.diagnostic.15": "...",

	"endOfPurchase.title": "Rachats",
	"endOfPurchase.id": "N°",
	"endOfPurchase.model": "Modèle",
	"endOfPurchase.customer": "Client",
	"endOfPurchase.amount": "Montant du rachat",

	"delivery.validation.title": "Validation",
	"delivery.validation.number_name": "N°, nom client",
	"delivery.validation.actions": "Actions",
	"delivery.validation.comment_ext": "Commentaire ext",
	"delivery.validation.comment_int": "Commentaire int",
	"delivery.validation.amount_total": "Montant total",
	"delivery.validation.amount": "Montant",
	"delivery.validation.select.bnc": "Carte bancaire",
	"delivery.validation.select.chk": "Chèque",
	"delivery.validation.select.csh": "Espèces",
	"delivery.validation.select.trn": "Virement",
	"delivery.validation.discount": "Remise",
	"delivery.validation.amountTotal": "Montant total",
	"delivery.validation.amountPaid": "Montant payé",
	"delivery.validation.payments": "Réglements",
	"delivery.validation.toPay": "Montant à payer",
	"delivery.validation.button": "Validation",
	"delivery.discount.title": "Remise",
	"delivery.payment.totalPrice": "Montant total",
	"delivery.payment.amountPaid": "Acompte",
	"delivery.payment.discount": "Remise",
	"delivery.payment.toPay": "Montant à payer",
	"delivery.payment.modal.title": "ppaiement",
	"delivery.payment.modal.text_1": "Il y a",
	"delivery.payment.modal.text_2": "interventions a restituer à ce client",
	"delivery.payment.modal.text_3": ", donc un encaissement global va être effectué",
	"delivery.noData": "Aucune réparation n'a été trouvée",
	"delivery.repair.title": "Interventions",
	"delivery.repair.dateEstimate": "Date de retour estimée",
	"delivery.repair.dateDeposit": "Date de dépôt",

	"control.search.label": "Recherche",
	"control.search.placeholder": "Recherche...",
	"control.customer.name.placeholder": "Nom",
	"control.customer.address.placeholder": "Adresse",
	"control.customer.city.placeholder": "Ville",
	"control.customer.firstName.placeholder": "Prénom",
	"control.customer.lastName.placeholder": "Nom",
	"control.customer.zipcode.placeholder": "Code postal",
	"control.customer.company.placeholder": "Société",
	"control.customer.email.placeholder": "E-mail",
	"control.customer.phone.placeholder": "N°téléphone",
	"control.customer.type.individual": "Particulier",
	"control.customer.type.professional": "Professionel",

	"repair.comment_ext.placeholder": "Commentaire ext...",
	"repair.comment_int.placeholder": "Commentaire int...",
	"repair.validation.title": "Validation",
	"repair.validation.repairId": "N°intervention",
	"repair.validation.deadline": "Date de retour prévue:",
	"repair.validation.customer": "Client:",
	"repair.validation.brandModel": "Produit:",
	"repair.validation.serialNumber": "N°série:",
	"repair.validation.accessory": "Accessoire:",
	"repair.validation.unlock": "Code de déverrouillage:",
	"repair.validation.call": "Appel client ?",
	"repair.validation.repairButton": "Réparé",
	"repair.validation.notRepairButton": "Irréparable",
	"repair.validation.waitingButton": "En attente de pièces",
	"repair.validation.status": "Statut",
	"repair.validation.submit": "Validation",
	"repair.filter.reset": "Réinitialiser le filtre",

	"repair_info.information.title": "Information réparation",
	"repair_info.information.id": "N°intervention",
	"repair_info.information.customer": "Client",
	"repair_info.information.model": "Modèle",
	"repair_info.information.serialNumber": "N°série",
	"repair_info.information.options": "Options",
	"repair_info.information.accessory": "Accessoire",
	"repair_info.information.temporary": "Prêt d'un appareil ?",
	"repair_info.information.call": "Appel client",
	"repair_info.information.amountTotal": "Montant total",
	"repair_info.information.amountDiscount": "Montant remise",
	"repair_info.information.amountPaid": "Montant payé",
	"repair_info.information.actions": "Actions",
	"repair_info.information.status": "Statut",
	"repair_info.information.dateDeposit": "Date de dépôt",
	"repair_info.information.dateEstimate": "Date de retour prévue",
	"repair_info.information.dateStatus": "Date du dernier statut",
	"repair_info.information.dateRepair": "Date de réparation",
	"repair_info.information.dateReturn": "Date de retour",
	"repair_info.information.comment_ext": "Commentaire ext",
	"repair_info.information.comment_int": "Commentaire int",
	"repair_info.information.repairsLog": "Historique",
	"repair_info.information.documents": "Documents",
	"repair_info.noData": "Aucune réparation n'a été trouvée",
	"repair_info.id": "N°",
	"repair_info.customer": "Client",
	"repair_info.status": "Statut",
	"repair_info.price": "Montant",
	"repair_info.back": "Retour",
	"repair_info.dateDeposit": "Date de dépôt",
	"repair_info.dateEstimate": "Date de retour estimée",
	"repair_info.log.date": "Date",
	"repair_info.log.status": "Statut",
	"repair_info.log.technician": "Technicien",
	"repair_info.documents.document": "Document",
	"repair_info.documents.date": "Date",

	"end_of_purchase.validation.title": "Validation",
	"end_of_purchase.validation.customer": "Client",
	"end_of_purchase.validation.brandModel": "Produit",
	"end_of_purchase.validation.serialNumber": "N°série",
	"end_of_purchase.validation.identity": "Référence du justificatif",
	"end_of_purchase.validation.identityType": "Type de justificatif",
	"end_of_purchase.validation.comment": "Commentaire",
	"end_of_purchase.validation.condition": "Etat",
	"end_of_purchase.validation.purchaseType": "Type de rachat",
	"end_of_purchase.validation.amount": "Montant",
	"end_of_purchase.validation.amountDiscount": "Montant remise",
	"end_of_purchase.validation.amountTotal": "Montant total",
	"end_of_purchase.validation.sellingPrice": "Prix de revente",
	"end_of_purchase.validation.dateReadySale": "Date de préparation",
	"end_of_purchase.validation.dateSale": "Date de vente",
	"end_of_purchase.validation.compliant": "Conforme",
	"end_of_purchase.validation.valid": "Validation",
	"end_of_purchase.noData": "Aucun achat trouvé",
	"end_of_purchase.validation.dateError": "Date incorrecte",

	"dashboard.select.group": "Sélectionner un groupe",
	"dashboard.select.workshop": "Sélectionnez un atelier",
	"dashboard.select.id": "Sélectionnez un atelier",

	"dashboard.global.title": "Tableau de bord global",
	"dashboard.global.detail": "Détail",
	"dashboard.detail.title": "Tableau de bord détaillé",
	"dashboard.detail.global": "Global",
	"dashboard.global.repairTitle": "Réparation",
	"dashboard.global.revenue": "Chiffre d'affaires mensuel",
	"dashboard.global.revenueForecast": "Prévision réparation",
	"dashboard.global.annualInvoice": "Facturation HT annuelle",
	"dashboard.global.purchaseForecast": "Prévision rachat",
	"dashboard.global.purchaseTitle": "Rachat",
	"dashboard.global.purchase": "Rachat",
	"dashboard.global.todayInvoice": "Facturation HT du jour",
	"dashboard.global.qtyRepairToday": "Quantité de réparation du jour",
	"dashboard.global.monthlyPurchase": "Rachat du mois",
	"dashboard.global.amount": "Montant",
	"dashboard.global.percentage": "%",
	"dashboard.global.average": "Moyenne",
	"dashboard.global.quantity": "Quantité",

	"dashboard.detail.amountRepairs": "Montant des réparations",
	"dashboard.detail.forecastRepair": "Prévision réparations",
	"dashboard.detail.qtyHandling": "Quantité de prise en charge",
	"dashboard.detail.qtyRepairs": "Quantité de réparations",
	"dashboard.detail.repairTime": "Délai de réparation",
	"dashboard.detail.averageCart": "Panier moyen",
	"dashboard.detail.qtyInvoice": "Quantité de factures",
	"dashboard.detail.qtyCreditNote": "Quantité d'avoirs",
	"dashboard.detail.qtyAfterSales": "Quantité de retour SAV",
	"dashboard.detail.repairs": "Réparations",
	"dashboard.detail.day": "Quantité jour",
	"dashboard.detail.week": "Quantité semaine",
	"dashboard.detail.month": "Quantité mois",
	"dashboard.detail.year": "Quantité année",
	"dashboard.detail.amountPurchases": "Montant des rachats",
	"dashboard.detail.forecastPurchase": "Prévision rachats",
	"dashboard.detail.qtyPurchases": "Quantité de rachats",
	"dashboard.detail.qtyNoncompliance": "Quantité de non conformité",
	"dashboard.detail.averageCartPurchases": "Panier moyen",
	"dashboard.detail.sellingTime": "Délai de vente",
	"dashboard.detail.purchases": "Rachats",
	"dashboard.detail.amountSelling": "Montant des ventes",
	"dashboard.detail.saleMargin": "Marge",
	"dashboard.detail.amountUnsold": "Montant des invendus",

	"attendance.attendance_inf.title": "Pointage employés",
	"attendance.user.nodata": "Aucun utilisateur trouvé",
	"attendance.tryAgain": "Réessayer",

	"form.user-profile.lastName": "Prénom",
	"form.user-profile.firstName": "Nom",
	"form.user-profile.phoneNumber": "N°téléphone",
	"form.user-profile.email": "E-mail",
	"form.user-profile.actualPassword": "Mot de passe actuel",
	"form.user-profile.newPassword": "Nouveau mot de passe",
	"form.user-profile.confirmPassword": "Confirmer le nouveau mot de passe",
	"form.user-profile.submit": "Validation",

	"models.list.title": "Liste des modèles",
	"models.list.id": "N°",
	"models.list.model": "Modèle",
	"models.list.device": "Type",
	"models.list.brand": "Marque",
	"models.list.active": "Actif ?",
	"models.list.new": " Nouveau modèle",

	"models.edit.title": "Modifiation d'un modèle",
	"models.edit.id": "N°",
	"models.edit.deviceTypes": "Type de produit",
	"models.edit.brands": "Marques",
	"models.edit.model": "Modèle",
	"models.edit.options": "Options",
	"models.edit.submit": "Validation",
	"models.edit.active": "Actif",
	"models.edit.modification": "Faire la modification pour tous les ateliers ?",
	"models.noData": "Pas de modèle trouvé",

	"models.new.title": "Nouveau modèle",
	"models.new.deviceTypes": "Type de produit",
	"models.new.brands": "Marques",
	"models.new.model": "Modèle",
	"models.new.options": "Options",
	"models.new.submit": "Validation",
	"models.new.active": "Actif",
	"models.new.modification": "Faire la modification pour tous les ateliers ?",

	"models.select.device": "Selectionner un type",
	"models.select.brand": "Selectionner une marque",

	"reports.document.title": "Documents",
	"reports.document.id": "Id",
	"reports.document.invoice": "Facture",
	"reports.document.quote": "Devis",
	"reports.document.handling": "Prise en charge",
	"reports.document.intervention": "Intervention",
	"reports.document.unrepairable": "Irréparabilité",
	"reports.document.recycling": "Recyclage",
	"reports.document.label": "Etiquette réparation",
	"reports.document.advance": "Acompte",
	"reports.document.buy": "Rachat",
	"reports.document.notrepaired": "Non réparé",
	"reports.document.order": "Bon de commande",
	"reports.document.label_purchase": "Etiquette rachat",
	"reports.document.reminder_quote": "Relance devis",
	"reports.document.repaired": "Réparation",
	"reports.document.waitforcustomer": "Attente client",

	"reports.document.INV": "Facture",
	"reports.document.QUT": "Devis",
	"reports.document.INT": "Intervention",
	"reports.document.UNR": "Irréparabilité",
	"reports.document.RCL": "Recyclage",
	"reports.document.LBL": "Etiquette réparation",
	"reports.document.ADV": "Acompte",
	"reports.document.PRC": "Rachat",
	"reports.document.NTR": "Non réparé",
	"reports.document.ORD": "Bon de commande",
	"reports.document.LBP": "Etiquette rachat",
	"reports.document.RQT": "Relance devis",
	"reports.document.RPR": "Réparation",
	"reports.document.WFC": "Attente client",

	"csv.detail.title": "Reportings csv",
	"csv.detail.list_action": "Liste des actions",
	"csv.detail.summary_intervention": "Récapitulatif des réparations",
	"csv.detail.list_stock_state": "Etat du stock",
	"csv.detail.list_stock_movement": "Liste des mouvements de stock",
	"csv.detail.list_stock_alert": "Liste des stocks d'alertes",
	"csv.detail.list_repair": "Liste des réparations",
	"csv.detail.list_payment": "Liste des paiements",
	"csv.detail.list_purchase": "Liste des rachats",
	"csv.detail.list_purchase_price": "Liste des prix de rachats",
	"csv.detail.list_customer": "Liste des clients",
	"csv.detail.summary_payment": "Récapitulatif des paiements",
	"csv.detail.export_accounting": "Export comptable",
	"csv.detail.list_invoice":"Liste des factures",
	"csv.detail.list_waiting":"Liste des réparations en attente",
	"csv.detail.export_global":"Export global",

	"import.detail.title": "Import",
	"import.detail.total": "Nombre de lignes total",
	"import.detail.error": "Nombre de lignes en erreur",
	"import.detail.link": "Lien de téléchargement du récapitulatif",
	"import.detail.uploadSuccess": "Fichier téléchargé avec succés",
	"import.detail.uploadError": "Erreur lors du téléchargement",

	"without-discount": "Sans",
	"without-group": "Sans",
	"without-workshop": "Sans",
	"without-identity": "Sans",
	"without-delay": "Sans",
	"without-stockType": "Sans",
	"without-paymentType": "Sans",
	"without-provider": "Sans",
	"without-order-type": "Sans",

	"filter.select.status": "--Selectionner un statut",

	"monthly_revenue.detail.title": "Chiffre d'affaires mensuel",
	"monthly_revenue.detail.date_end": "Date de début",
	"monthly_revenue.detail.date_start": "Date de fin",
	"monthly_revenue.detail.revenue": "Chiffre d'affaires",
	"monthly_revenue.detail.modalTitle": "Chiffre d'affaires mensuels",
	"monthly_revenue.detail.noRevenue": "Aucun chiffre d'affaires à valider",
	"monthly_revenue.detail.tryAgain": "Réessayer",
	"monthly_revenue.detail.submit": "Validation",
	"monthly_revenue.detail.button.detail": "Télécharger le détail",
	"monthly_revenue.detail.day": "Jour",
	"monthly_revenue.detail.month": "Mois",
	"monthly_revenue.detail.year": "Année",
	"monthly_revenue.ok.button.submit": "OK",
	"monthly_revenue.ok.text": "Chiffres d'affaires validé",
	"monthly_revenue.summary.title": "Récapitulatif des paiements",
	"monthly_revenue.summary.payment_type": "Mode de paiement",
	"monthly_revenue.summary.amount": "Montant",

	"modal.logout.title": "Déconnexion",
	"modal.logout.text": "Etes vous sur de vouloir vous déconnecter ?",
	"modal.logout.success": "Déconnexion",

	"repairs.list.title": "Liste des Interventions",
	"repairs.list.id": "N°",
	"repairs.list.customer": "Client",
	"repairs.list.status": "Statut",
	"repairs.list.amount": "Montant total",
	"repairs.list.model": "Modèle",

	"repairs.edit.title": "Modification de réparation",
	"repairs.edit.id": "N°intervention",
	"repairs.edit.customer": "Client",
	"repairs.edit.model": "Modèle",
	"repairs.edit.serialNumber": "N°série",
	"repairs.edit.options": "Options",
	"repairs.edit.amountTotal": "Montant total",
	"repairs.edit.amountDiscount": "Montant remise",
	"repairs.edit.amountPaid": "Montant payé",
	"repairs.edit.comment_ext": "Commentaire...",
	"repairs.edit.comment_int": "Commentaire int...",
	"repairs.edit.actions": "Actions",
	"repairs.edit.status": "Statut",
	"repairs.edit.dateDeposit": "Date de dépôt",
	"repairs.edit.dateEstimate": "Date de retour prévue",
	"repairs.edit.dateStatus": "Date de statut",
	"repairs.edit.dateRepair": "Date de réparation",
	"repairs.edit.dateReturn": "Date de retour",
	"repairs.edit.payments": "Paiements",
	"repairs.edit.submit": "Validation",
	"repairs.edit.ok": "OK",
	"repairs.noData": "Aucune réparation n'a été trouvée",
	"repairs.edit.list.id": "N°",
	"repairs.edit.list.name": "Nom",
	"repairs.edit.list.phone": "téléphone",
	"repairs.edit.list.city": "Ville",
	"repairs.edit.list.caption": "Caption",
	"repairs.edit.list.actions": "Actions",
	"repairs.edit.check.accessory": "Accessoire ?",
	"repairs.edit.check.device": "Prêt ?",
	"repairs.edit.check.call": "Appel ?",
	"repairs.edit.discount": "Remise",
	"repairs.edit.noDiscount": "Pas de remise",
	"repairs.paymentMsgPart1": "Trop perçu",
	"repairs.paymentMsgPart2": "causé par le dernier paiement",
	"repairs.errorMsgAction": "Pas d'actions pour ce modèle",
	"repairs.paymentGroup.date": "Date",

	"purchases.list.title": "List des rachats",
	"purchases.list.id": "N°",
	"purchases.list.customer": "Client",
	"purchases.list.model": "Modèle",
	"purchases.list.condition": "Etat",
	"purchases.list.type": "Type",
	"purchases.list.amountPurchase": "Montant du rachat",
	"purchases.list.sellingPrice": "Prix de vente",
	"purchases.list.dateDeposit": "Date de dépôt",
	"purchases.list.dateReady": "Date de préparation",
	"purchases.list.dateSelling": "Date de vente",

	"purchases.edit.title": "Modification de rachat",
	"purchases.edit.id": "N°rachat",
	"purchases.edit.customer": "Client",
	"purchases.edit.model": "Modèle",
	"purchases.edit.serialNumber": "N°série",
	"purchases.edit.cardIdNumber": "Référence du justificatif",
	"purchases.edit.identity": "Type de justificatif",
	"purchases.edit.comment": "Commentaire...",
	"purchases.edit.condition": "Etat",
	"purchases.edit.type": "Type de rachat",
	"purchases.edit.amount": "Montant du rachat",
	"purchases.edit.sellingPrice": "Prix de revente",
	"purchases.edit.dateDeposit": "Date de depôt",
	"purchases.edit.dateReady": "Date de préparation",
	"purchases.edit.options": "Options",
	"purchases.edit.options.compliant": "Conforme ?",
	"purchases.edit.dateSelling": "Date de revente",
	"purchases.edit.discount": "Montant de la décôte",
	"purchases.edit.submit": "Validation",
	"purchases.noData": "Aucun achat trouvé",
	"purchases.edit.list.id": "N°",
	"purchases.edit.list.name": "Nom",
	"purchases.edit.list.phone": "Téléphone",
	"purchases.edit.list.city": "Ville",
	"purchases.edit.list.caption": "libellé",

	"employees.list.title": "List des employés",
	"employees.list.login": "Identifiant",
	"employees.list.lastName": "Nom",
	"employees.list.firstName": "Prénom",
	"employees.list.email": "E-mail",
	"employees.list.phone": "Téléphone",
	"employees.list.active": "Actif",
	"employees.list.new": " Nouvel employé",
	"employees.list.role": "Rôle",
	"employees.list.id": "N°",

	"employees.edit.title": "Modification d'un employé",
	"employees.edit.login": "Identifiant",
	"employees.edit.lastName": "Nom",
	"employees.edit.firstName": "Prénom",
	"employees.edit.phone": "N°téléphone",
	"employees.edit.email": "E-mail",
	"employees.edit.options": "Options",
	"employees.edit.password": "Nouveau mot de passe",
	"employees.edit.confirmPassword": "Confirmer le nouveau mot de passe",
	"employees.edit.birthDate": "Date de naissance",
	"employees.edit.role": "Rôle",
	"employees.edit.workshops": "Ateliers",
	"employees.edit.submit": "Validation",
	"employees.edit.active": "Actif",
	"employees.edit.id": "N°",
	"employees.selectImg": "Image",
	"employees.noData": "Pas d'employés",

	"employees.new.title": "Création d'un employé",
	"employees.new.login": "Identifiant",
	"employees.new.lastName": "Nom",
	"employees.new.firstName": "Prénom",
	"employees.new.phone": "N°téléphone",
	"employees.new.email": "E-mail",
	"employees.new.options": "Options",
	"employees.new.password": "Mot de passe",
	"employees.new.confirmPassword": "Confirmer le mot de passe",
	"employees.new.birthDate": "Date de naissance",
	"employees.new.role": "Rôle",
	"employees.new.submit": "Validation",
	"employees.new.active": "Actif",

	"employees.passwordError": "Mot de passe incorrect",
	"employees.emailError": "E-mail incorrect",
	"employees.weakPasswordError": "Le mot de passe ne respecte pas les règles de sécurité (au minimum : 8 caractères, 1 chiffre, 1 minuscule, 1 majuscule)",
	"employees.chooseRole": "Selectionner un rôle",

	"customers.list.title": "Liste des clients",
	"customers.list.name": "Nom",
	"customers.list.phone": "Téléphone",
	"customers.list.city": "Ville",
	"customers.list.active": "Actif",
	"customers.list.new": " Nouveau client",
	"customers.list.id": "N°",
	"customers.list.email": "E-mail",

	"customers.edit.id": "N°",
	"customers.edit.title": "Modification d'un client",
	"customers.edit.lastName": "Nom",
	"customers.edit.firstName": "Prénom",
	"customers.edit.companyName": "Société",
	"customers.edit.email": "E-mail",
	"customers.edit.phone": "N°téléphone",
	"customers.edit.type": "Type",
	"customers.edit.zipCode": "Code postal",
	"customers.edit.city": "Ville",
	"customers.edit.address": "Adresse",
	"customers.edit.discount": "Remise",
	"customers.edit.options": "Options",
	"customers.edit.submit": "Validation",
	"customers.edit.active": "Actif",
	"customers.edit.cardIdNumber": "Numéro de carte",
	"customers.edit.identity": "Identity",
	"customers.edit.paymentDelay": "Délai de paiement",
	"customers.new.title": "Création d'un client",
	"customers.new.submit": "Validation",
	"customers.new.active": "Actif",
	"customers.emailErrort": "E-mail incorrect",

	"new-message.title": "Envoyer un nouveau message",
	"new-message.error.empty-message-text": "Le contenu est vide!",
	"new-message.error.no-selected-users": "Veuillez selectionner un destinataire!",
	"new-message.search.placeholder": "Employé...",
	"new-message.text.placeholder": "Message...",
	"new-message.button.text": "Envoyer",

	"noInformation": "Pas d'information disponible",

	"customers.noData": "Aucun client trouvé",

	"endOfTheRepair.noData": "Aucune réparation n'a été trouvée",
	"endOfTheRepair.repair.title": "Interventions",
	"endOfTheRepair.repair.status": "Statut",
	"endOfTheRepair.repair.depositDate": "Date de dépôt",
	"endOfTheRepair.repair.estimatedDate": "Date de retour prévue",
	"endOfTheRepair.summary.title": "Récapitulatif",
	"endOfTheRepair.summary.status": "Statut",
	"endOfTheRepair.summary.quantity": "Quantité",
	"endOfTheRepair.summary.amount": "Montant",

	"actions.list.title": "Liste des actions",
	"actions.list.id": "N°",
	"actions.list.caption": "Description",
	"actions.list.amount": "Montant",
	"actions.list.new": " Nouvelle action",
	"actions.list.model": "Modèle",
	"actions.list.active": "Actif ?",
	"actions.edit-action": "Modifier l'action",
	"actions.group.no_actions": "Pas d'actions",
	"actions.change": "Modifier",
	"actions.backToModel": "Retour au modèle",
	"actions.noData": "Aucune action trouvée",
	"actions.addModelButton": "Ajouter un modèle",

	"workshops.noData": "Aucun atelier n'a été trouvé",

	"workshops.list.title": "Liste des ateliers",
	"workshops.list.id": "Id",
	"workshops.list.caption": "Nom",
	"workshops.list.city": "Ville",
	"workshops.list.email": "E-mail",
	"workshops.list.phone": "Téléphone ",
	"workshops.list.active": "Actif ?",
	"workshops.list.new": " Nouvel Atelier",

	"workshops.edit.title": "Modifiation d'un atelier",
	"workshops.edit.id": "Id",
	"workshops.edit.caption": "Description",
	"workshops.edit.address": "Adresse",
	"workshops.edit.zipCode": "Code postal",
	"workshops.edit.city": "Ville",
	"workshops.edit.phone": "Téléphone",
	"workshops.edit.email": "E-mail",
	"workshops.edit.options": "Options",
	"workshops.edit.submit": "Validation",
	"workshops.edit.active": "Actif ?",
	"workshops.edit.information": "Information",
	"workshops.edit.workshopGroup": "Groupe",
	"workshops.edit.workshops": "Dupliquer les prix depuis l'atelier",
	"workshops.edit.forecastRepair": "Prévision montant réparation",
	"workshops.edit.forecastPurchase": "Prévision montant rachat",
	"workshops.edit.bankName": "Nom de la banque",
	"workshops.edit.bankDetail": "RIB",

	"workshops.new.title": "Nouvel atelier",
	"workshops.new.caption": "Description",
	"workshops.new.address": "Adresse",
	"workshops.new.zipCode": "Code postal",
	"workshops.new.city": "Ville",
	"workshops.new.phone": "Téléphone",
	"workshops.new.email": "E-mail",
	"workshops.new.options": "Options",
	"workshops.new.submit": "Validation",
	"workshops.new.active": "Actif ?",
	"workshops.new.information": "Information",
	"workshops.new.template": "Modèle d'email",
	"workshops.emailError": "e-mail incorrect",
	"workshops.new.workshopGroup": "Groupe",
	"workshops.new.workshops": "Dupliquer les prix depuis l'atelier",
	"workshops.new.forecastRepair": "Réparation prévue",
	"workshops.new.forecastPurchase": "Achat prévu",
	"workshops.new.bankName": "Bank name",
	"workshops.new.bankDetail": "Bank detail",

	"modal.authorization": "Autorisation",

	"searchTable.title.default": "Liste",
	"searchTable.list.id": "N°",
	"searchTable.list.name": "Nom",
	"searchTable.list.phone": "Téléphone",
	"searchTable.list.city": "Ville",
	"searchTable.list.caption": "Description",

	"actions.edit.title": "Modification d'une action",
	"actions.new.title": "Nouvelle action",
	"actions.model": "Modèle",
	"actions.duration": "Temps de réparation",
	"actions.caption": "Description",
	"actions.link": "Lien",
	"actions.note": "Note %",
	"actions.comment": "Commentaire...",
	"actions.valid": "Validation",
	"actions.ref": "Référence",
	"actions.qtyStockActual": "Quantity en stock",
	"actions.amountWithoutTax": "Montant HT",
	"actions.qtyStockAlert": "Quantité d'alerte",
	"actions.buyingPriceWithoutTax": "Prix d'achat HT",
	"actions.qtyAddedOrRemoved": "Quantité +/-",
	"actions.active": "Actif ?",
	"actions.flagRepair": "Flag réparation",
	"actions.orderQuantity": "Quantité de commande",
	"actions.flagPurchase": "Flag rachat",
	"actions.flagExpress": "Flag express",
	"actions.flagSerial": "Flag N°série ?",
	"actions.manageStock": "Gestion en stock ?",
	"actions.modification": "Faire la modification pour tous les ateliers ?",
	"actions.action": "Action",
	"actions.params": "Paramètre",
	"actions.stock": "Stock",
	"actions.create": "Validation",
	"actions.id": "N°",
	"actions.select.movement": "Selectionner un type de mouvement",

	"brand.list.title": "Liste des marques",
	"brand.edit.title": "Modification d'une marque",
	"brand.new.title": "Ajout d'une marque",
	"brand.list.new": "Ajouter une marque",

	"device_type.list.new": "Ajouter un type de produit",
	"device_type.list.title": "Liste des types de produit",
	"device_type.edit.title": "Modification d'un type de produit",
	"device_type.new.title": "Ajout d'un type de produit",

	"discount_type.list.title": "Liste des remises",
	"discount_type.edit.title": "Modification d'une remise",
	"discount_type.new.title": "Ajout d'une remise",
	"discount_type.list.new": "Ajouter une remise",

	"identity_type.list.title": "Liste des preuves d'identité",
	"identity_type.edit.title": "Modification d'une preuve d'identité",
	"identity_type.new.title": "Ajout d'une preuve d'identité",
	"identity_type.list.new": "Ajouter une preuve d'identité",

	"payment_type.list.title": "Liste des types de paiements",
	"payment_type.edit.title": "Modification d'un type de paiement",
	"payment_type.new.title": "Ajout d'un type de paiement",
	"payment_type.list.new": "Ajouter d'un type de paiement",

	"provider.list.title": "Liste des fournisseurs",
	"provider.edit.title": "Modification d'un fournisseur",
	"provider.new.title": "Ajout d'un fournisseur",
	"provider.list.new": "Ajouter un fournisseur",

	"template.list.title": "Liste des modèles d'email",
	"template.edit.title": "Modification d'un modèle d'email",
	"template.new.title": "Ajout d'un modèle d'email",
	"template.list.new": "Ajouter un modèle d'email",

	"purchase_type.list.title": "Liste des type de rachats",
	"purchase_type.edit.title": "Modification d'un type de rachat",
	"purchase_type.new.title": "Ajout d'un type de rachat",
	"purchase_type.list.new": "Ajouter un type de rachat",

	"status.list.title": "Liste des statuts",
	"status.edit.title": "Modification d'un statut",
	"status.new.title": "Ajout d'un statut",
	"status.list.new": "Ajouter un statut",

	"stock_type.list.title": "Liste des mouvements de stock",
	"stock_type.edit.title": "Modification d'un mouvement de stock",
	"stock_type.new.title": "Ajout d'un mouvement de stock",
	"stock_type.list.new": "Ajouter un mouvement de stock",

	"workshop_group.list.title": "Liste des groupes d'ateliers",
	"workshop_group.edit.title": "Modification d'un groupe d'atelier",
	"workshop_group.new.title": "Ajout d'un groupe d'atelier",
	"workshop_group.list.new": "Ajouter d'un groupe d'atelier",

	"payment_delay.list.title": "Liste des délais de paiement",
	"payment_delay.edit.title": "Modification d'un délai de paiement",
	"payment_delay.new.title": "Ajout d'un délai de paiement",
	"payment_delay.list.new": "Ajouter un délai de paiement",

	"invoice.list.title": "Liste des factures",
	"invoice.new.title": "Création d'une facture",
	"invoice.list.new": "Ajouter une facture",
	"invoice.list.id": "N°",
	"invoice.list.ref": "Reference",
	"invoice.list.creationDate": "Date de facturation",
	"invoice.list.customerName": "Client",
	"invoice.list.amountWithoutTax": "Montant HT",
	"invoice.list.amountWithTax": "Montant TTC",
	"invoice.edit.title": "Modification d'une facture",
	"invoice.edit.header": "Entête",
	"invoice.edit.payments": "Paiements",
	"invoice.edit.lines": "Lignes",
	"invoice.edit.total": "Total",
	"invoice.edit.id": "N°",
	"invoice.edit.ref": "Réference",
	"invoice.edit.creationDate": "Date de facturation",
	"invoice.edit.dueDate": "Date d'échéance",
	"invoice.edit.customer": "Client",
	"invoice.edit.discount": "Remise",
	"invoice.edit.paymentDelay": "Délais de paiement",
	"invoice.edit.amountWithoutTax": "Montant HT",
	"invoice.edit.amountDiscount": "Montant de la remise",
	"invoice.edit.amountTax": "Montant TVA",
	"invoice.edit.amountWithTax": "Montant TTC",
	"invoice.edit.caption": "Action",
	"invoice.edit.amount": "Montant",
	"invoice.edit.quantity": "Quantité",
	"invoice.edit.price": "Prix",
	"invoice.edit.date": "Date",
	"invoice.edit.submit": "Valider",
	"invoice.edit.paymentType": "Mode de paiement",
	"invoice.edit.paymentsSumMoreThanTotalWithTax": "La somme des paiements doit être inférieure ou égale au total TTC",

	"role.list.title": "Liste des rôles",
	"role.edit.title": "Modification d'un rôle",
	"role.new.title": "Ajout d'un rôle",
	"role.list.new": "Ajouter un rôle",

	"toolbar.hint.home": "Accueil",
	"toolbar.hint.alarm": "En cours",
	"toolbar.hint.attendance": "Pointage",
	"toolbar.hint.dashboard": "Tableau de bord",
	"toolbar.hint.menu": "Administration",
	"toolbar.hint.messages": "Messages",
	"toolbar.hint.user": "Profil",
	"toolbar.hint.changeWorkshop": "Changer d'atelier",

	"common.noData": "Pas d'enregistrement trouvé",
	"common.noPayment": "Pas de paiements",
	"common.list.id": "Id",
	"common.list.caption": "Libellé",
	"common.field.id": "Id",
	"common.field.caption": "Libellé",
	"common.field.options": "Options",
	"common.field.active": "Actif",
	"common.field.reset": "Réinitialiser",
	"common.field.repair": "Flag date réparation ?",
	"common.field.returnBack": "Flag date retour ?",
	"common.field.noInvoice": "Pas de facture",
	"common.field.endOfRepair": "End of repair",
	"common.field.percentage": "%",
	"common.field.reference": "Référence",
	"common.field.delay": "Delai",
	"common.field.warranty": "Information de garantie",
	"common.field.displayInvoice": "Afficher la facture (en retour client) ?",
	"common.field.days": "Délai (en nombre de jours)",
	"common.field.endOfMonth": "Fin de mois ?",
	"common.field.autoCreate": "Création automatique",
	"common.field.content": "Contenu",
	"common.button.save": "Valider",
	"common.button.create": "Valider",
	"common.button.cancel": "Annuler",
	"common.manageRole.menuList": "Liste des menus disponibles",
	"common.manageRole.menuRoleList": "Liste des menus accessibles pour ce rôle",

	"is-active.yes": "Oui",
	"is-active.no": "Non",

	"button.submit.processing": "Traitement...",
	"button.change": "Modifier",

	"selectWorkshop.workshops": "Ateliers",

	"popup.afterSales.title": "Vérification SAV",
	"popup.afterSales.text": "Ce client a déjà déposé un produit du même modèle en réparation, est ce une prise en charge SAV ?",
	"popup.afterSales.ok": "Ok",

	"tile.qtyStock": "Quantité en stock",
	"tile.delay": "Délai fournisseur",

	"error.403.session": "Erreur de connexion, veuillez vérifier l'identifiant et le mot de passe",
	"error.404.session": "Erreur de connexion, veuillez contacter l'administrateur",
	"error.401.session": "Erreur de connexion, veuillez vérifier l'identifiant et le mot de passe",
	"error.wrongPassword.session": "erreur de mot de passe",
	"error.401.auth": "Erreur de connexion, veuillez vérifier l'identifiant et le mot de passe",
	"error.unhandled": "Erreur système",
	"error.400.wizard": "Erreur de requête",
	"error.403.wizard": "Erreur d'authentification",
	"error.404.wizard": "Pas d'enregistrement trouvé",
	"error.400.upload": "Erreur de téléchargement",
	"error.403.upload": "Erreur de téléchargement",
	"error.404.upload": "Erreur de téléchargement",

	"error.500": "Erreur serveur",
	"error.500.detail": "Erreur serveur",
	"error.500.detail.action": "Erreur serveur",
	"error.500.detail.action_parameter": "Erreur serveur",
	"error.500.detail.customer": "Erreur serveur",
	"error.500.detail.dashboard": "Erreur serveur",
	"error.500.detail.model": "Erreur serveur",
	"error.500.detail.profile": "Erreur serveur",
	"error.500.detail.purchase": "Erreur serveur",
	"error.500.detail.repair": "Erreur serveur",
	"error.500.detail.report/csv": "Erreur serveur",
	"error.500.detail.report/pdf": "Erreur serveur",
	"error.500.detail.statistic": "Erreur serveur",
	"error.500.detail.user": "Erreur serveur",
	"error.500.detail.workshop": "Erreur serveur",
	"error.500.list": "Erreur serveur",
	"error.500.list.actions": "Erreur serveur",
	"error.500.list.brand": "Erreur serveur",
	"error.500.list.condition": "Erreur serveur",
	"error.500.list.csv": "Erreur serveur",
	"error.500.list.customer_type": "Erreur serveur",
	"error.500.list.customers": "Erreur serveur",
	"error.500.list.device_type": "Erreur serveur",
	"error.500.list.discount_type": "Erreur serveur",
	"error.500.list.identity": "Erreur serveur",
	"error.500.list.messages": "Erreur serveur",
	"error.500.list.models": "Erreur serveur",
	"error.500.list.payment_type": "Erreur serveur",
	"error.500.list.purchase_type": "Erreur serveur",
	"error.500.list.purchases": "Erreur serveur",
	"error.500.list.repairs": "Erreur serveur",
	"error.500.list.reports": "Erreur serveur",
	"error.500.list.role": "Erreur serveur",
	"error.500.list.status": "Erreur serveur",
	"error.500.list.stock_type": "Erreur serveur",
	"error.500.list.users": "Erreur serveur",
	"error.500.list.workshops": "Erreur serveur",
	"error.500.list.repairs_pagination": "Erreur serveur",
	"error.500.messages": "Erreur serveur",

	"error.405": "Erreur de paramètre",
	"error.405.detail": "Erreur de paramètre",
	"error.405.detail.action": "Erreur de paramètre",
	"error.405.detail.action_parameter": "Erreur de paramètre",
	"error.405.detail.customer": "Erreur de paramètre",
	"error.405.detail.dashboard": "Erreur de paramètre",
	"error.405.detail.model": "Erreur de paramètre",
	"error.405.detail.profile": "Erreur de paramètre",
	"error.405.detail.purchase": "Erreur de paramètre",
	"error.405.detail.repair": "Erreur de paramètre",
	"error.405.detail.report/csv": "Erreur de paramètre",
	"error.405.detail.report/pdf": "Erreur de paramètre",
	"error.405.detail.statistic": "Erreur de paramètre",
	"error.405.detail.user": "Erreur de paramètre",
	"error.405.detail.workshop": "Erreur de paramètre",
	"error.405.list": "Erreur de paramètre",
	"error.405.list.actions": "Erreur de paramètre",
	"error.405.list.brand": "Erreur de paramètre",
	"error.405.list.condition": "Erreur de paramètre",
	"error.405.list.csv": "Erreur de paramètre",
	"error.405.list.customer_type": "Erreur de paramètre",
	"error.405.list.customers": "Erreur de paramètre",
	"error.405.list.device_type": "Erreur de paramètre",
	"error.405.list.discount_type": "Erreur de paramètre",
	"error.405.list.identity": "Erreur de paramètre",
	"error.405.list.messages": "Erreur de paramètre",
	"error.405.list.models": "Erreur de paramètre",
	"error.405.list.payment_type": "Erreur de paramètre",
	"error.405.list.purchase_type": "Erreur de paramètre",
	"error.405.list.purchases": "Erreur de paramètre",
	"error.405.list.repairs": "Erreur de paramètre",
	"error.405.list.reports": "Erreur de paramètre",
	"error.405.list.role": "Erreur de paramètre",
	"error.405.list.status": "Erreur de paramètre",
	"error.405.list.stock_type": "Erreur de paramètre",
	"error.405.list.users": "Erreur de paramètre",
	"error.405.list.workshops": "Erreur de paramètre",
	"error.405.list.repairs_pagination": "Erreur de paramètre",
	"error.405.messages": "Erreur de paramètre",

	"error.200": "Erreur lors du traitement de la réponse du serveur",
	"error.200.detail": "Erreur lors du traitement de la réponse du serveur",
	"error.200.detail.action": "Erreur lors du traitement de la réponse du serveur",
	"error.200.detail.action_parameter": "Erreur lors du traitement de la réponse du serveur",
	"error.200.detail.customer": "Erreur lors du traitement de la réponse du serveur",
	"error.200.detail.dashboard": "Erreur lors du traitement de la réponse du serveur",
	"error.200.detail.model": "Erreur lors du traitement de la réponse du serveur",
	"error.200.detail.profile": "Erreur lors du traitement de la réponse du serveur",
	"error.200.detail.purchase": "Erreur lors du traitement de la réponse du serveur",
	"error.200.detail.repair": "Erreur lors du traitement de la réponse du serveur",
	"error.200.detail.report/csv": "Erreur lors du traitement de la réponse du serveur",
	"error.200.detail.report/pdf": "Erreur lors du traitement de la réponse du serveur",
	"error.200.detail.statistic": "Erreur lors du traitement de la réponse du serveur",
	"error.200.detail.user": "Erreur lors du traitement de la réponse du serveur",
	"error.200.detail.workshop": "Erreur lors du traitement de la réponse du serveur",
	"error.200.list": "Erreur lors du traitement de la réponse du serveur",
	"error.200.list.actions": "Erreur lors du traitement de la réponse du serveur",
	"error.200.list.brand": "Erreur lors du traitement de la réponse du serveur",
	"error.200.list.condition": "Erreur lors du traitement de la réponse du serveur",
	"error.200.list.csv": "Erreur lors du traitement de la réponse du serveur",
	"error.200.list.customer_type": "Erreur lors du traitement de la réponse du serveur",
	"error.200.list.customers": "Erreur lors du traitement de la réponse du serveur",
	"error.200.list.device_type": "Erreur lors du traitement de la réponse du serveur",
	"error.200.list.discount_type": "Erreur lors du traitement de la réponse du serveur",
	"error.200.list.identity": "Erreur lors du traitement de la réponse du serveur",
	"error.200.list.messages": "Erreur lors du traitement de la réponse du serveur",
	"error.200.list.models": "Erreur lors du traitement de la réponse du serveur",
	"error.200.list.payment_type": "Erreur lors du traitement de la réponse du serveur",
	"error.200.list.purchase_type": "Erreur lors du traitement de la réponse du serveur",
	"error.200.list.purchases": "Erreur lors du traitement de la réponse du serveur",
	"error.200.list.repairs": "Erreur lors du traitement de la réponse du serveur",
	"error.200.list.reports": "Erreur lors du traitement de la réponse du serveur",
	"error.200.list.role": "Erreur lors du traitement de la réponse du serveur",
	"error.200.list.status": "Erreur lors du traitement de la réponse du serveur",
	"error.200.list.stock_type": "Erreur lors du traitement de la réponse du serveur",
	"error.200.list.users": "Erreur lors du traitement de la réponse du serveur",
	"error.200.list.workshops": "Erreur lors du traitement de la réponse du serveur",
	"error.200.list.repairs_pagination": "Erreur lors du traitement de la réponse du serveur",
	"error.200.messages": "Erreur lors du traitement de la réponse du serveur"
}
