module.exports={
  "tile.Handling": "Handling",
  "tile.EndOfRepair": "End of repair",
  "tile.EndOfPurchase": "End of purchase",
  "tile.Customer": "Customer",
  "tile.DeliveryToCustomer": "Delivery to customer",
  "tile.ProductPurchase": "Product Purchase",
  "tile.RepairInformation": "Repair information",
  "tile.Dashboard": "Dashboard",
  "tile.other": "Other",

   "admin.Repairs": "Repairs",
   "admin.PurchasesDevices": "Purchases",
   "admin.Employees": "Employees",
   "admin.Customers": "Customers",
   "admin.Models": "Models",
   "admin.Actions": "Actions",
   "admin.Parts": "Parts",
   "admin.Workshops": "Workshops",
   "admin.Reports": "Reports (PDF)",
   "admin.CSV": "Reports (CSV)",
   "admin.Import": "Import",
   "admin.MonthlyRevenue": "Validation of revenue",
   "admin.title": "Administrative menu",
   "admin.Brand": "Brand",
   "admin.DeviceType": "Device type",
   "admin.DiscountType": "Discount type",
   "admin.IdentityType": "Identity type",
   "admin.PaymentType": "Payment type",
   "admin.PurchaseType": "Purchase type",
   "admin.Status": "Status",
   "admin.StockType": "Stock type",
   "admin.WorkshopGroup": "Workshop group",
   "admin.PaymentDelay": "Payment delay",
   "admin.Role": "Role",
   "admin.Invoice": "Invoice",
   "admin.Provider": "Provider",
   "admin.DecreaseStock": "Stock movement",
   "admin.Template": "Template",

   "header.menu.user.title": "User Menu",
   "header.menu.user.profile": "User Profile",
   "header.menu.user.logout": "Logout",

  "404.header.text": "Page Not Found",
  "404.info.top.text": "Oops! Sorry, that page couldn't be found.",
  "404.info.down.text": "Is there a typo in the url? Or try the search bar below.",
  "404.link.home": "Go home",

  "breadcrumbs": "Home",
  "breadcrumbs.user-profile": "User profile",
  "breadcrumbs.repair": "Repair",
  "breadcrumbs.repair_info": "Repair info",
  "breadcrumbs.attendance": "Attendance",
  "breadcrumbs.models": "Models",
  "breadcrumbs.repairs": "Repairs",
  "breadcrumbs.reports": "Reports",
  "breadcrumbs.dashboard": "Dashboard",
  "breadcrumbs.purchase": "Purchase",
  "breadcrumbs.delivery": "Delivery",
  "breadcrumbs.handling": "Handling",
  "breadcrumbs.coming-soon": "Coming soon",
  "breadcrumbs.csv": "CSV",
  "breadcrumbs.end_of_purchase": "End of purchase",
  "breadcrumbs.monthly_revenue": "Validation of revenue",
  "breadcrumbs.purchases": "Purchases",
  "breadcrumbs.employees": "Employees",
  "breadcrumbs.customers": "Customers",
  "breadcrumbs.actions": "Actions",
  "breadcrumbs.workshops": "Workshops",
  "breadcrumbs.brand": "Brand",
  "breadcrumbs.device_type": "Device type",
  "breadcrumbs.discount_type": "Discount type",
  "breadcrumbs.identity_type": "Identity type",
  "breadcrumbs.payment_type": "Payment type",
  "breadcrumbs.purchase_type": "Purchase type",
  "breadcrumbs.status": "Status",
  "breadcrumbs.stock_type": "Stock type",
  "breadcrumbs.workshop_group": "Workshop group",
  "breadcrumbs.payment_delay": "Payment delay",
  "breadcrumbs.role": "Role",
  "breadcrumbs.invoice": "Invoice",
  "breadcrumbs.provider": "Provider",
  "breadcrumbs.decrease_stock": "Stock movement",
  "breadcrumbs.template": "Template",

  "wizard.handling.device": "Device",
  "wizard.handling.brand": "Brand",
  "wizard.handling.model": "Model",
  "wizard.handling.customer": "Customer",
  "wizard.handling.options": "Options",
  "wizard.handling.advance": "Advance",
  "wizard.handling.action": "Actions",
  "wizard.handling.validation": "Validation",
  "wizard.delivery.return": "Return",
  "wizard.delivery.validation": "Validation",
  "wizard.purchase.device": "Device",
  "wizard.purchase.brand": "Brand",
  "wizard.purchase.model": "Model",
  "wizard.purchase.action": "Actions",
  "wizard.purchase.condition": "Condition",
  "wizard.purchase.customer": "Customer",
  "wizard.purchase.options": "Options",
  "wizard.purchase.tests": "Tests",
  "wizard.purchase.validation": "Validation",
  "wizard.repair.order": "Order",
  "wizard.repair.validation": "Validation",
  "wizard.repair.action": "Actions",
  "wizard.dashboard.global": "Global",
  "wizard.dashboard.detail": "Details",
  "wizard.repair_info.information": "Information",
  "wizard.attendance.attendance_inf": "Employee attendance",
  "wizard.models.models_list": "Models list",
  "wizard.models.new": "Model new",
  "wizard.models.edit": "Model edit",
  "wizard.reports.document": "Documents",
  "wizard.reports.csv": "CSV",
  "wizard.repairs.repairs_list": "Repairs list",
  "wizard.repairs.edit": "Repair edit",
  "wizard.end_of_purchase.purchase": "Purchase",
  "wizard.end_of_purchase.validation": "Validation",
  "wizard.purchases.purchases_list": "Purchase list",
  "wizard.purchases.edit": "Purchase edit",
  "wizard.delivery.discount": "Discount",
  "wizard.delivery.payment": "Payment",
  "wizard.employees.employees_list": "Employee list",
  "wizard.employees.edit": "Employee edit",
  "wizard.employees.new": "Employee new",
  "wizard.customers.customers_list": "Customers list",
  "wizard.customers.edit": "Customer edit",
  "wizard.customers.new": "Customer new",
  "wizard.actions.actions_list": "Actions list",
  "wizard.actions.edit": "Action edit",
  "wizard.actions.new": "Action new",
  "wizard.workshops.workshops_list": "Workshops list",
  "wizard.workshops.edit": "Workshop edit",
  "wizard.workshops.new": "Workshop new",
  "wizard.brand.list": "Brand list",
  "wizard.brand.edit": "Brand edit",
  "wizard.brand.new": "Brand new",
  "wizard.device_type.list": "Device type list",
  "wizard.device_type.edit": "Device type edit",
  "wizard.device_type.new": "Device type new",
  "wizard.discount_type.list": "Discount list",
  "wizard.discount_type.edit": "Discount edit",
  "wizard.discount_type.new": "Discount new",
  "wizard.identity_type.list": "Identity list",
  "wizard.identity_type.edit": "Identity edit",
  "wizard.identity_type.new": "Identity new",
  "wizard.payment_type.list": "Payment list",
  "wizard.payment_type.edit": "Payment edit",
  "wizard.payment_type.new": "Payment new",
  "wizard.purchase_type.list": "Purchase list",
  "wizard.purchase_type.edit": "Purchase edit",
  "wizard.purchase_type.new": "Purchase new",
  "wizard.status.list": "Status list",
  "wizard.status.edit": "Status edit",
  "wizard.status.new": "Status new",
  "wizard.stock_type.list": "Stock type list",
  "wizard.stock_type.edit": "Stock type edit",
  "wizard.stock_type.new": "Stock type new",
  "wizard.workshop_group.list": "Workshop group list",
  "wizard.workshop_group.edit": "Workshop group edit",
  "wizard.workshop_group.new": "Workshop group new",
  "wizard.payment_delay.list": "Payment delay list",
  "wizard.payment_delay.edit": "Payment delay edit",
  "wizard.payment_delay.new": "Payment delay new",
  "wizard.role.list": "Role list",
  "wizard.role.edit": "Role edit",
  "wizard.role.new": "Role new",
  "wizard.invoice.list": "Invoice list",
  "wizard.invoice.edit": "Invoice edit",
  "wizard.invoice.new": "Invoice new",
  "wizard.provider.list": "Provider list",
  "wizard.provider.edit": "Provider edit",
  "wizard.provider.new": "Provider new",
  "wizard.decrease_stock.edit": "Stock movement edit",
  "wizard.decrease_stock.actions_list": "Stock movement action_list",
  "wizard.template.list": "Template list",
  "wizard.template.edit": "Template edit",
  "wizard.template.new": "Template new",

  "login.button.submit": "Valid",
  "login.input.username.placeholder": "Username",
  "login.input.password.placeholder": "Password",

  "select-workshop.button.submit": "Proceed",
  "select-workshop.h4": "Select workshop",
  "select-workshop.nodata.text": "Sorry, no workshops available for you",
  "select-workshop.nodata.btn": "Logout",
  "select.no-options": "no options",
  "handling.customer.title": "Customer Information",
  "handling.customer.submit": "Proceed",
  "handling.options.title": "Options",
  "handling.options.tempdevice": "Temporary device?",
  "handling.options.accessory": "Accessory?",
  "handling.options.quote": "Quote?",
  "handling.options.partorder": "Part order?",
  "handling.options.serialnumber": "Serial Number",
  "handling.options.unlockcode": "Unlock Code",
  "handling.options.comment_ext": "Free comment...",
  "handling.options.comment_int": "Free comment int...",
  "handling.options.submit": "Next",
  "handling.actions.submit": "Next",
  "handling.advance.title": "Advance",
  "handling.advance.amount": "Amount...",
  "handling.advance.select.card": "Bank Card",
  "handling.advance.select.check": "Check",
  "handling.advance.select.cash": "Cash",
  "handling.advance.submit": "Next",
  "handling.advance.error": "the advance can not exceed the total amount",
  "handling.validation.title": "Validation",
  "handling.validation.number": "Number Repair",
  "handling.validation.brandmodel": "Brand Model",
  "handling.validation.date": "Estimated Date",
  "handling.validation.name": "Customer Name",
  "handling.validation.actions": "Actions",
  "handling.validation.amount": "Amount",
  "handling.validation.submit": "Valid",
  "handling.device.nodata.text": "No devices found",
  "handling.device": "Device",
  "handling.brand.nodata.text": "No brands found",
  "handling.brand": "Brand",
  "handling.model": "Model",
  "handling.model.noData": "No models found",
  "handling.actions": "Actions",
  "handling.actions.amount": "Amount",
  "handling.actions.nodata.text": "No actions found",
  "handling.customers.nodata.text": "No customers found",
  "handling.advance.nodata.text": "No payment types found",
  "handling.customer.table.name": "Name",
  "handling.customer.table.phone": "Phone",
  "handling.customer.table.city": "City",
  "handling.emailError": "incorrect email",

  "tryAgain": "Try again",


  "purchase.condition.title": "Condition",
  "purchase.condition.price": "Price",
  "purchase.condition.discount": "Discount amount",
  "purchase.condition.total": "Total",
  "purchase.condition.submit": "Next",
  "purchase.options.title": "Options",
  "purchase.options.serialnumber": "Serial Number",
  "purchase.options.unlockcode": "Unlock Code",
  "purchase.options.comment": "Free comment...",
  "purchase.options.identification": "Reference of the identification",
  "purchase.options.submit": "Next",
  "purchase.tests.title": "Tests",
  "purchase.validation.title": "Validation",
  "purchase.validation.name": "Name Customer",
  "purchase.validation.brandmodel": "Brand Model",
  "purchase.validation.serialnumber": "Serial Number",
  "purchase.validation.select.identity": "IDENTITY",
  "purchase.validation.select.passport": "PASSPORT",
  "purchase.validation.comment": "Free comment...",
  "purchase.validation.condition": "Condition Device",
  "purchase.validation.price": "Price Device",
  "purchase.validation.submit": "Valid",
  "purchase.options.modalTitle": "Signature",
  "purchase.options.modalInput": "Input Signature",
  "purchase.options.pad.label": "Signature",
  "purchase.options.pad.clear": "Clear",
  "purchase.options.pad.ok": "OK",

  "purchase.diagnostic.1": "Diagnostic 1",
  "purchase.diagnostic.2": "Diagnostic 2",
  "purchase.diagnostic.3": "Diagnostic 3",
  "purchase.diagnostic.4": "Diagnostic 4",
  "purchase.diagnostic.5": "Diagnostic 5",
  "purchase.diagnostic.6": "Diagnostic 6",
  "purchase.diagnostic.7": "Diagnostic 7",
  "purchase.diagnostic.8": "Diagnostic 8",
  "purchase.diagnostic.9": "Diagnostic 9",
  "purchase.diagnostic.10": "Diagnostic 10",
  "purchase.diagnostic.11": "Diagnostic 11",
  "purchase.diagnostic.12": "Diagnostic 12",
  "purchase.diagnostic.13": "Diagnostic 13",
  "purchase.diagnostic.14": "Diagnostic 14",
  "purchase.diagnostic.15": "Diagnostic 15",

  "endOfPurchase.title": "Purchase",
  "endOfPurchase.id": "Id",
  "endOfPurchase.model": "Model",
  "endOfPurchase.customer": "Customer",
  "endOfPurchase.amount": "Amount purchase",

  "delivery.validation.title": "Validation",
  "delivery.validation.number_name": "Number, Name Customer",
  "delivery.validation.actions": "Actions",
  "delivery.validation.comment_ext": "Comments ext",
  "delivery.validation.comment_int": "Comments int",
  "delivery.validation.amount_total": "Total Price",
  "delivery.validation.amount": "Amount",
  "delivery.validation.select.bnc": "Bank card",
  "delivery.validation.select.chk": "Check",
  "delivery.validation.select.csh": "Cash",
  "delivery.validation.select.trn": "Transfert",
  "delivery.validation.discount": "Discount",
  "delivery.validation.amountTotal": "Total amount",
  "delivery.validation.amountPaid": "Paid amount",
  "delivery.validation.payments": "Payments",
  "delivery.validation.toPay": "Amount for pay",
  "delivery.validation.button": "Valid",
  "delivery.discount.title": "Discount",
  "delivery.payment.totalPrice": "Total price",
  "delivery.payment.amountPaid": "Advance payment",
  "delivery.payment.discount": "Discount chosen",
  "delivery.payment.toPay": "Amount for pay",
  "delivery.payment.modal.title": "Payment",
  "delivery.payment.modal.text_1": "There are",
  "delivery.payment.modal.text_2": "interventions to be returned for this client",
  "delivery.payment.modal.text_3": ", so a global payment will be made",
  "delivery.noData": "No repairs found",
  "delivery.repair.title": "Repairs",
  "delivery.repair.dateEstimate": "Estimated date",
  "delivery.repair.dateDeposit": "Deposit date",

  "control.search.label": "Search",
  "control.search.placeholder": "search...",
  "control.customer.name.placeholder": "Name",
  "control.customer.address.placeholder": "Address",
  "control.customer.city.placeholder": "City",
  "control.customer.firstName.placeholder": "First Name",
  "control.customer.lastName.placeholder": "Last Name",
  "control.customer.zipcode.placeholder": "Zip Code",
  "control.customer.company.placeholder": "Company name",
  "control.customer.email.placeholder": "E-mail",
  "control.customer.phone.placeholder": "Phone",
  "control.customer.type.individual": "Individual",
  "control.customer.type.professional": "Professional",

  "repair.comment_ext.placeholder": "Free text ext...",
  "repair.comment_int.placeholder": "Free text int...",
  "repair.validation.title": "Validation",
  "repair.validation.repairId": "Repair ID:",
  "repair.validation.deadline": "Deadline:",
  "repair.validation.customer": "Customer name and phone number:",
  "repair.validation.brandModel": "Brand and model:",
  "repair.validation.serialNumber": "Serial number:",
  "repair.validation.accessory": "Accessory:",
  "repair.validation.unlock": "Unlock code:",
  "repair.validation.call": "Customer called?",
  "repair.validation.repairButton": "Repair",
  "repair.validation.notRepairButton": "Unrepairable",
  "repair.validation.waitingButton": "Waiting for part",
  "repair.validation.status": "Status",
  "repair.validation.submit": "Valid",
  "repair.filter.reset": "Reset filter",

  "repair_info.information.title": "Repairs Information",
  "repair_info.information.id": "Id",
  "repair_info.information.customer": "Customer",
  "repair_info.information.model": "Model",
  "repair_info.information.serialNumber": "Serial Number",
  "repair_info.information.options": "Options",
  "repair_info.information.accessory": "Accessory",
  "repair_info.information.temporary": "Temporary device",
  "repair_info.information.call": "Call",
  "repair_info.information.amountTotal": "Amount total",
  "repair_info.information.amountDiscount": "Amount discount",
  "repair_info.information.amountPaid": "Amount paid",
  "repair_info.information.actions": "Actions",
  "repair_info.information.status": "Status",
  "repair_info.information.dateDeposit": "Date deposit",
  "repair_info.information.dateEstimate": "Date estimate return",
  "repair_info.information.dateStatus": "Date status",
  "repair_info.information.dateRepair": "Date repair",
  "repair_info.information.dateReturn": "Date return",
  "repair_info.information.comment_ext": "Comment ext",
  "repair_info.information.comment_int": "Comment int",
  "repair_info.information.repairsLog": "Repairs log",
  "repair_info.information.documents": "Documents",
  "repair_info.noData": "No repairs found",
  "repair_info.id": "Id",
  "repair_info.customer": "Customer",
  "repair_info.status": "Status",
  "repair_info.price": "Price",
  "repair_info.back": "Back",
  "repair_info.dateDeposit": "Deposit date",
  "repair_info.dateEstimate": "Estimated date",
  "repair_info.log.date": "Date",
  "repair_info.log.status": "Status",
  "repair_info.log.technician": "Technician",
  "repair_info.documents.document": "Document",
  "repair_info.documents.date": "Date",

  "end_of_purchase.validation.title": "Validation",
  "end_of_purchase.validation.customer": "Cutromer",
  "end_of_purchase.validation.brandModel": "Brand Model",
  "end_of_purchase.validation.serialNumber": "Serial number",
  "end_of_purchase.validation.identity": "Reference of the identification",
  "end_of_purchase.validation.identityType": "Identification document",
  "end_of_purchase.validation.comment": "Comment",
  "end_of_purchase.validation.condition": "Condition",
  "end_of_purchase.validation.purchaseType": "Purchase type",
  "end_of_purchase.validation.amount": "Amount",
  "end_of_purchase.validation.amountDiscount": "Amount discount",
  "end_of_purchase.validation.amountTotal": "Amount total",
  "end_of_purchase.validation.sellingPrice": "Selling Price",
  "end_of_purchase.validation.dateReadySale": "Date of ready for sale",
  "end_of_purchase.validation.dateSale": "Date of sale",
  "end_of_purchase.validation.compliant": "Compliant",
  "end_of_purchase.validation.valid": "Valid",
  "end_of_purchase.noData": "No purchases found",
  "end_of_purchase.validation.dateError": "incorrect date",

  "dashboard.select.group": "Select group",
  "dashboard.select.workshop": "Select workshop",
  "dashboard.select.id": "Select workshop",

  "dashboard.global.title": "Dashboard global",
  "dashboard.global.detail": "Detail",
  "dashboard.detail.title": "Dashboard detail",
  "dashboard.detail.global": "Global",
  "dashboard.global.repairTitle": "Repair",
  "dashboard.global.revenue": "Monthly revenue",
  "dashboard.global.revenueForecast": "Forecast repair",
  "dashboard.global.purchaseTitle": "Purchase",
  "dashboard.global.purchase": "Monthly purchase",
  "dashboard.global.purchaseForecast": "Forecast purchase",
  "dashboard.global.annualInvoice": "Annual invoice",
  "dashboard.global.todayInvoice": "Today invoice",
  "dashboard.global.qtyRepairToday": "Qty repair today",
  "dashboard.global.monthlyPurchase": "Monthly purchase",
  "dashboard.global.amount": "Amount",
  "dashboard.global.percentage": "Percentage",
  "dashboard.global.average": "Average",
  "dashboard.global.quantity": "Quantity",

  "dashboard.detail.amountRepairs": "Amount repairs",
  "dashboard.detail.forecastRepair": "Forecast repair",
  "dashboard.detail.qtyHandling": "Qty handling",
  "dashboard.detail.qtyRepairs": "Qty repairs",
  "dashboard.detail.repairTime": "Repair time",
  "dashboard.detail.averageCart": "Average cart",
  "dashboard.detail.qtyInvoice": "Qty invoice",
  "dashboard.detail.qtyCreditNote": "Qty credit note",
  "dashboard.detail.qtyAfterSales": "Qty after sales",
  "dashboard.detail.repairs": "Repairs",
  "dashboard.detail.day": "Qty day (xx/xx/xxxx - xx/xx/xxxx)",
  "dashboard.detail.week": "Qty week (Monday - xx/xx/xxxx)",
  "dashboard.detail.month": "Qty month (01/xx/xxxx - xx/xx/xxxx)",
  "dashboard.detail.year": "Qty year (01/01/xxxx - xx/xx/xxxx)",
  "dashboard.detail.amountPurchases": "Amount purchases",
  "dashboard.detail.forecastPurchase": "Forecast purchase",
  "dashboard.detail.qtyPurchases": "Qty purchases",
  "dashboard.detail.qtyNoncompliance": "Qty noncompliance",
  "dashboard.detail.averageCartPurchases": "Average cart",
  "dashboard.detail.sellingTime": "Selling time",
  "dashboard.detail.purchases": "Purchases",
  "dashboard.detail.amountSelling": "Amount Selling",
  "dashboard.detail.saleMargin": "Sale Margin",
  "dashboard.detail.amountUnsold": "Amount of unsold",

  "attendance.attendance_inf.title": "Employee attendance",
  "attendance.user.nodata": "No users found.",
  "attendance.tryAgain": "Try again",

  "form.user-profile.lastName": "Last Name",
  "form.user-profile.firstName": "First Name",
  "form.user-profile.phoneNumber": "Phone number",
  "form.user-profile.email": "Email",
  "form.user-profile.actualPassword": "Actual Password",
  "form.user-profile.newPassword": "New Password",
  "form.user-profile.confirmPassword": "Confirm New Password",
  "form.user-profile.submit": "Valid",

  "models.list.title": "Models list",
  "models.list.id": "Id",
  "models.list.model": "Model",
  "models.list.device": "Device type",
  "models.list.brand": "Brand",
  "models.list.active": "Active",
  "models.list.new": " New model",

  "models.edit.title": "Model edit",
  "models.edit.id": "Id",
  "models.edit.deviceTypes": "Device type",
  "models.edit.brands": "Brands",
  "models.edit.model": "Model",
  "models.edit.options": "Options",
  "models.edit.submit": "Save",
  "models.edit.active": "Active",
  "models.edit.modification": "Do modification for all workshop?",
  "models.noData": "No models found",

  "models.new.title": "Model new",
  "models.new.deviceTypes": "Device type",
  "models.new.brands": "Brands",
  "models.new.model": "Model",
  "models.new.options": "Options",
  "models.new.submit": "Create",
  "models.new.active": "Active",
  "models.new.modification": "Do modification for all workshop?",

  "models.select.device": "Choose device type",
  "models.select.brand": "Choose brand",

  "reports.document.title": "Documents",
  "reports.document.id": "ID",
  "reports.document.invoice": "Invoice",
  "reports.document.quote": "Quote",
  "reports.document.handling": "Handling",
  "reports.document.intervention": "Intervention",
  "reports.document.unrepairable": "Unrepairable",
  "reports.document.recycling": "Recycling",
  "reports.document.label": "Label",
  "reports.document.advance": "Advance",
  "reports.document.buy": "Purchase",
  "reports.document.notrepaired": "Not repaired",
  "reports.document.order": "Order",

  "csv.detail.title": "CSV",
  "csv.detail.list_action": "List of actions",
  "csv.detail.summary_intervention": "Summary of repairs",
  "csv.detail.list_stock_state": "Stock state",
  "csv.detail.list_stock_movement": "List of stock movements",
  "csv.detail.list_stock_alert": "List of stock alerts",
  "csv.detail.list_repair": "List of repairs",
  "csv.detail.list_payment": "List of payments",
  "csv.detail.list_purchase": "List of purchases",
  "csv.detail.list_purchase_price": "List of prices of purchase",
  "csv.detail.list_customer": "List of customers",
  "csv.detail.summary_payment": "Summary of payments",
  "csv.detail.export_accounting": "Accounting export",
  "csv.detail.list_invoice":"List of invoice",

  "import.detail.title": "Import",
  "import.detail.total": "Total lines processed",
  "import.detail.error": "Error count",
  "import.detail.link": "Link to download",
  "import.detail.uploadSuccess": "Success upload to server",
  "import.detail.uploadError": "Error upload to server",

  "without-discount": "Without discount",
  "without-group": "Without group",
  "without-workshop": "None",
  "without-identity": "Without identity",
  "without-delay": "Without delay",
  "without-paymentType": "Without payment type",
  "without-stockType": "Choose stock type",
  "without-customer": "Without customer",
  "without-provider": "Without provider",
  "without-order-type": "Choose order type",

  "filter.select.status": "--Select status",

  "monthly_revenue.detail.title": "Validation of revenue",
  "monthly_revenue.detail.date_end": "Date End",
  "monthly_revenue.detail.date_start": "Date Start",
  "monthly_revenue.detail.revenue": "Revenue",
  "monthly_revenue.detail.modalTitle": "Validation of revenue",
  "monthly_revenue.detail.noRevenue": "No revenue found to validate",
  "monthly_revenue.detail.tryAgain": "Try again",
  "monthly_revenue.detail.submit": "Valid",
  "monthly_revenue.detail.button.detail": "Download detail",
  "monthly_revenue.detail.day": "Day",
  "monthly_revenue.detail.month": "Month",
  "monthly_revenue.detail.year": "Year",
  "monthly_revenue.ok.button.submit": "OK",
  "monthly_revenue.ok.text": "OK, Revenue is validated",
  "monthly_revenue.summary.title": "Summary",
  "monthly_revenue.summary.payment_type": "Payment type",
  "monthly_revenue.summary.amount": "Amount",

  "modal.logout.title": "Logout",
  "modal.logout.text": "Logout? Realy?",
  "modal.logout.success": "Logout",

  "repairs.list.title": "Repairs list",
  "repairs.list.id": "Id",
  "repairs.list.customer": "Customer",
  "repairs.list.status": "Status",
  "repairs.list.amount": "Amount total",
  "repairs.list.model": "Model",

  "repairs.edit.title": "Repair edit",
  "repairs.edit.id": "Id",
  "repairs.edit.customer": "Customer",
  "repairs.edit.model": "Model",
  "repairs.edit.serialNumber": "Serial number",
  "repairs.edit.options": "Options",
  "repairs.edit.amountTotal": "Amount total",
  "repairs.edit.amountDiscount": "Amount discount",
  "repairs.edit.amountPaid": "Amount paid",
  "repairs.edit.comment_ext": "Comment...",
  "repairs.edit.comment_int": "Comment int...",
  "repairs.edit.actions": "Actions",
  "repairs.edit.status": "Status",
  "repairs.edit.dateDeposit": "Date deposit",
  "repairs.edit.dateEstimate": "Date estimate",
  "repairs.edit.dateStatus": "Date status",
  "repairs.edit.dateRepair": "Date repair",
  "repairs.edit.dateReturn": "Date return",
  "repairs.edit.payments": "Payments",
  "repairs.edit.submit": "Save",
  "repairs.edit.ok": "OK",
  "repairs.noData": "No repairs found",
  "repairs.edit.list.id": "Id",
  "repairs.edit.list.name": "Name",
  "repairs.edit.list.phone": "Phone",
  "repairs.edit.list.city": "City",
  "repairs.edit.list.caption": "Caption",
  "repairs.edit.list.actions": "Actions",
  "repairs.edit.check.accessory": "Accessory",
  "repairs.edit.check.device": "Temporary device",
  "repairs.edit.check.call": "Call",
  "repairs.edit.discount": "Discount",
  "repairs.edit.noDiscount": "No discount",
  "repairs.paymentMsgPart1": "overpayment for",
  "repairs.paymentMsgPart2": "input in last payment",
  "repairs.errorMsgAction": "No actions for this model",
  "repairs.paymentGroup.date": "Date",

  "purchases.list.title": "Purchase list",
  "purchases.list.id": "Id",
  "purchases.list.customer": "Customer",
  "purchases.list.model": "Model",
  "purchases.list.condition": "Condition",
  "purchases.list.type": "Type",
  "purchases.list.amountPurchase": "Amount purchase",
  "purchases.list.sellingPrice": "Selling price",
  "purchases.list.dateDeposit": "Date deposit",
  "purchases.list.dateReady": "Preparation date",
  "purchases.list.dateSelling": "Date selling",

  "purchases.edit.title": "Purchase edit",
  "purchases.edit.id": "Id",
  "purchases.edit.customer": "Customer",
  "purchases.edit.model": "Model",
  "purchases.edit.serialNumber": "Serial number",
  "purchases.edit.cardIdNumber": "Card id number",
  "purchases.edit.identity": "Identity",
  "purchases.edit.comment": "Comment...",
  "purchases.edit.condition": "Condition",
  "purchases.edit.type": "Type purchase",
  "purchases.edit.amount": "Amount purchase",
  "purchases.edit.sellingPrice": "Selling price",
  "purchases.edit.dateDeposit": "Date deposit",
  "purchases.edit.dateReady": "Date ready",
  "purchases.edit.options": "Options",
  "purchases.edit.options.compliant": "Compliant",
  "purchases.edit.dateSelling": "Date selling",
  "purchases.edit.discount": "Amount discount",
  "purchases.edit.submit": "Save",
  "purchases.noData": "No purchases found",
  "purchases.edit.list.id": "Id",
  "purchases.edit.list.name": "Name",
  "purchases.edit.list.phone": "Phone",
  "purchases.edit.list.city": "City",
  "purchases.edit.list.caption": "Caption",

  "employees.list.title": "Employees list",
  "employees.list.login": "Login",
  "employees.list.lastName": "Last name",
  "employees.list.firstName": "First name",
  "employees.list.email": "Email",
  "employees.list.phone": "Phone",
  "employees.list.active": "Active",
  "employees.list.new": " New employee",
  "employees.list.role": "Role",
  "employees.list.id": "Id",

  "employees.edit.title": "Employees edit",
  "employees.edit.login": "Login",
  "employees.edit.lastName": "Last Name",
  "employees.edit.firstName": "First Name",
  "employees.edit.phone": "Phone number",
  "employees.edit.email": "Email",
  "employees.edit.options": "Options",
  "employees.edit.password": "New Password",
  "employees.edit.confirmPassword": "Confirm New Password",
  "employees.edit.birthDate": "Birth date",
  "employees.edit.role": "Role",
  "employees.edit.workshops": "Workshops",
  "employees.edit.submit": "Save",
  "employees.edit.active": "Active",
  "employees.edit.id": "Id",
  "employees.selectImg": "Select image",
  "employees.noData": "No employees found",

  "employees.new.title": "Employees new",
  "employees.new.login": "Login",
  "employees.new.lastName": "Last Name",
  "employees.new.firstName": "First Name",
  "employees.new.phone": "Phone number",
  "employees.new.email": "Email",
  "employees.new.options": "Options",
  "employees.new.password": "Password",
  "employees.new.confirmPassword": "Confirm Password",
  "employees.new.birthDate": "Birth date",
  "employees.new.role": "Role",
  "employees.new.submit": "Create",
  "employees.new.active": "Active",

  "employees.passwordError": "passwords do not match or is empty",
  "employees.emailError": "incorrect email",
  "employees.weakPasswordError": "the password is not comply with safety criteria: at least 1 uppercase, 1 lowercase, 1 number, minimum 8 characters",
  "employees.chooseRole": "Choose role",

  "customers.list.title": "Customer list",
  "customers.list.name": "Name",
  "customers.list.phone": "Phone",
  "customers.list.city": "City",
  "customers.list.active": "Active",
  "customers.list.new": " New customer",
  "customers.list.id": "Id",
  "customers.list.email": "Email",

  "customers.edit.id": "Id",
  "customers.edit.title": "Customer edit",
  "customers.edit.lastName": "Last Name",
  "customers.edit.firstName": "First Name",
  "customers.edit.companyName": "Company Name",
  "customers.edit.email": "Email",
  "customers.edit.phone": "Phone",
  "customers.edit.type": "Type customer",
  "customers.edit.zipCode": "Zip code",
  "customers.edit.city": "City",
  "customers.edit.address": "Address",
  "customers.edit.discount": "Discount",
  "customers.edit.options": "Options",
  "customers.edit.submit": "Save",
  "customers.edit.active": "Active",
  "customers.edit.cardIdNumber": "Card id number",
  "customers.edit.identity": "Identity",
  "customers.edit.paymentDelay": "Payment delay",
  "customers.new.title": "Customer new",
  "customers.new.submit": "Create",
  "customers.new.active": "Active",
  "customers.emailError": "incorrect email",

  "new-message.title": "Send new message",
  "new-message.error.empty-message-text": "Message text is empty!",
  "new-message.error.no-selected-users": "No selected users!",
  "new-message.search.placeholder": "user name...",
  "new-message.text.placeholder": "message...",
  "new-message.button.text": "Send",

  "noInformation": "No information available",

  "customers.noData": "No customers found",

  "endOfTheRepair.noData": "No repairs found",
  "endOfTheRepair.repair.title": "Repairs",
  "endOfTheRepair.repair.status": "Status",
  "endOfTheRepair.repair.depositDate": "Deposit date",
  "endOfTheRepair.repair.estimatedDate": "Estimated date",
  "endOfTheRepair.summary.title": "Summary",
  "endOfTheRepair.summary.status": "Status",
  "endOfTheRepair.summary.quantity": "Quantity",
  "endOfTheRepair.summary.amount": "Amount",

  "actions.list.title": "Actions list",
  "actions.list.id": "Id",
  "actions.list.caption": "Caption",
  "actions.list.amount": "Amount",
  "actions.list.new": " New action",
  "actions.list.model": "Model",
  "actions.list.active": "Active",
  "actions.edit-action": "Edit Action",
  "actions.group.no_actions": "no actions",
  "actions.change": "Change",
  "actions.backToModel": "Back to model",
  "actions.noData": "No actions found",
  "actions.addModelButton": "Add model",

  "workshops.noData": "No workshops found",

  "workshops.list.title": "Workshops list",
  "workshops.list.id": "Id",
  "workshops.list.caption": "Caption",
  "workshops.list.city": "City",
  "workshops.list.email": "Email",
  "workshops.list.phone": "Phone",
  "workshops.list.active": "Active",
  "workshops.list.new": " New workshop",

  "workshops.edit.title": "Workshop edit",
  "workshops.edit.id": "Id",
  "workshops.edit.caption": "Caption",
  "workshops.edit.address": "Address",
  "workshops.edit.zipCode": "Zip code",
  "workshops.edit.city": "City",
  "workshops.edit.phone": "Phone",
  "workshops.edit.email": "Email",
  "workshops.edit.options": "Options",
  "workshops.edit.submit": "Save",
  "workshops.edit.active": "Active",
  "workshops.edit.information": "Information",
  "workshops.edit.workshopGroup": "Workshop group",
  "workshops.edit.workshops": "Duplicate price from workshop",
  "workshops.edit.forecastRepair": "Forecast repair",
  "workshops.edit.forecastPurchase": "Forecast purchase",
  "workshops.edit.bankName": "Bank name",
  "workshops.edit.bankDetail": "Bank detail",

  "workshops.new.title": "Workshop new",
  "workshops.new.caption": "Caption",
  "workshops.new.address": "Address",
  "workshops.new.zipCode": "Zip code",
  "workshops.new.city": "City",
  "workshops.new.phone": "Phone",
  "workshops.new.email": "Email",
  "workshops.new.options": "Options",
  "workshops.new.submit": "Create",
  "workshops.new.active": "Active",
  "workshops.new.information": "Information",
  "workshops.new.template": "Template",
  "workshops.emailError": "incorrect email",
  "workshops.new.workshopGroup": "Workshop group",
  "workshops.new.workshops": "Duplicate price from workshop",
  "workshops.new.forecastRepair": "Forecast repair",
  "workshops.new.forecastPurchase": "Forecast purchase",
  "workshops.new.bankName": "Bank name",
  "workshops.new.bankDetail": "Bank detail",

  "modal.authorization": "Authorization",

  "searchTable.title.default": "List",
  "searchTable.list.id": "Id",
  "searchTable.list.name": "Name",
  "searchTable.list.phone": "Phone",
  "searchTable.list.city": "City",
  "searchTable.list.caption": "Caption",

  "actions.edit.title": "Action edit",
  "actions.new.title": "Action new",
  "actions.model": "For model",
  "actions.duration": "Duration",
  "actions.caption": "Caption",
  "actions.link": "Link",
  "actions.note": "Note",
  "actions.comment": "Comment...",
  "actions.valid": "Valid",
  "actions.ref": "Ref",
  "actions.qtyStockActual": "Qty stock actual",
  "actions.amountWithoutTax": "Amount without tax",
  "actions.qtyStockAlert": "Qty stock alert",
  "actions.buyingPriceWithoutTax": "Buying price without tax",
  "actions.qtyAddedOrRemoved": "Qty added or removed",
  "actions.active": "Active?",
  "actions.flagRepair": "Flag repair",
  "actions.orderQuantity": "Quantity to order",
  "actions.flagPurchase": "Flag purchase",
  "actions.flagExpress": "Flag express",
  "actions.flagSerial": "Flag serial",
  "actions.manageStock": "Manage stock?",
  "actions.modification": "Do modification for all workshop?",
  "actions.action": "Action",
  "actions.params": "Params",
  "actions.stock": "Stock",
  "actions.create": "Create",
  "actions.id": "Id",
  "actions.select.movement": "Choose movment type",

  "brand.list.title": "Brand list",
  "brand.edit.title": "Brand edit",
  "brand.new.title": "Brand new",
  "brand.list.new": "New brand",

  "device_type.list.new": "New device type",
  "device_type.list.title": "Device type list",
  "device_type.edit.title": "Device type edit",
  "device_type.new.title": "Device type new",

  "discount_type.list.title": "Discount type list",
  "discount_type.edit.title": "Discount type edit",
  "discount_type.new.title": "Discount type new",
  "discount_type.list.new": "New discount type",

  "identity_type.list.title": "Identity type list",
  "identity_type.edit.title": "Identity type edit",
  "identity_type.new.title": "Identity type new",
  "identity_type.list.new": "New identity type",

  "payment_type.list.title": "Payment type list",
  "payment_type.edit.title": "Payment type edit",
  "payment_type.new.title": "Payment type new",
  "payment_type.list.new": "New payment type",

  "provider.list.title": "Provider list",
  "provider.edit.title": "Provider edit",
  "provider.new.title": "Provider new",
  "provider.list.new": "New provider",

  "template.list.title": "Template list",
  "template.edit.title": "Template edit",
  "template.new.title": "Template new",
  "template.list.new": "New template",

  "purchase_type.list.title": "Purchase type list",
  "purchase_type.edit.title": "Purchase type edit",
  "purchase_type.new.title": "Purchase type new",
  "purchase_type.list.new": "New purchase type",

  "status.list.title": "Status list",
  "status.edit.title": "Status edit",
  "status.new.title": "Status new",
  "status.list.new": "New status",

  "stock_type.list.title": "Stock type list",
  "stock_type.edit.title": "Stock type edit",
  "stock_type.new.title": "Stock type new",
  "stock_type.list.new": "New stock type",

  "workshop_group.list.title": "Workshop group list",
  "workshop_group.edit.title": "Workshop group edit",
  "workshop_group.new.title": "Workshop group new",
  "workshop_group.list.new": "New workshop group",

  "payment_delay.list.title": "Payment delay list",
  "payment_delay.edit.title": "Payment delay edit",
  "payment_delay.new.title": "Payment delay new",
  "payment_delay.list.new": "New payment delay",

  "invoice.list.title": "Invoice list",
  "invoice.new.title": "Invoice new",
  "invoice.list.new": "New invoice",
  "invoice.list.id": "ID",
  "invoice.list.ref": "Reference",
  "invoice.list.creationDate": "Creation Date",
  "invoice.list.customerName": "Customer Name",
  "invoice.list.amountWithoutTax": "Amount without tax",
  "invoice.list.amountWithTax": "Amount with tax",
  "invoice.edit.title": "Invoice edit",
  "invoice.edit.header": "Header",
  "invoice.edit.payments": "Payments",
  "invoice.edit.lines": "Lines",
  "invoice.edit.total": "Total",
  "invoice.edit.id": "ID",
  "invoice.edit.ref": "Reference",
  "invoice.edit.creationDate": "Creation Date",
  "invoice.edit.dueDate": "Due Date",
  "invoice.edit.customer": "Customer",
  "invoice.edit.discount": "Discount",
  "invoice.edit.paymentDelay": "Payment Delay",
  "invoice.edit.amountWithoutTax": "Amount Without Tax",
  "invoice.edit.amountDiscount": "Amount Discount",
  "invoice.edit.amountTax": "Amount Tax",
  "invoice.edit.amountWithTax": "Amount With Tax",
  "invoice.edit.caption": "Caption",
  "invoice.edit.amount": "Amount",
  "invoice.edit.quantity": "Quantity",
  "invoice.edit.price": "Price",
  "invoice.edit.date": "Date",
  "invoice.edit.submit": "Save",
  "invoice.edit.paymentType": "Payment Type",
  "invoice.edit.paymentsSumMoreThanTotalWithTax": "sum payments must be less or equal to total with tax",

  "role.list.title": "Role list",
  "role.edit.title": "Role edit",
  "role.new.title": "Role new",
  "role.list.new": "New role",

  "toolbar.hint.home": "Home",
  "toolbar.hint.alarm": "Alarm",
  "toolbar.hint.attendance": "Attendance",
  "toolbar.hint.dashboard": "Dashboard",
  "toolbar.hint.menu": "Menu",
  "toolbar.hint.messages": "Messages",
  "toolbar.hint.user": "User",
  "toolbar.hint.changeWorkshop": "Change workshop",

  "common.noData": "No data found",
  "common.noPayment": "no payment",
  "common.list.id": "Id",
  "common.list.caption": "Caption",
  "common.field.id": "Id",
  "common.field.caption": "Caption",
  "common.field.options": "Options",
  "common.field.active": "Active",
  "common.field.reset": "Reset",
  "common.field.repair": "Repair",
  "common.field.returnBack": "Return",
  "common.field.noInvoice": "No invoice",
  "common.field.endOfRepair": "End of repair",
  "common.field.percentage": "Percentage",
  "common.field.reference": "Reference",
  "common.field.delay": "Delay",
  "common.field.warranty": "Warranty information",
  "common.field.displayInvoice": "Display invoice in return screen",
  "common.field.days": "Delay of payment (in days)",
  "common.field.endOfMonth": "End of month",
  "common.field.autoCreate": "Auto create",
  "common.field.content": "Content",
  "common.button.save": "Save",
  "common.button.create": "Create",
  "common.button.cancel": "Cancel",
  "common.manageRole.menuList": "List of menus availables",
  "common.manageRole.menuRoleList": "List of menus granted to this role",

  "is-active.yes": "yes",
  "is-active.no": "no",

  "button.submit.processing": "Processing...",
  "button.change": "Change",

  "selectWorkshop.workshops": "Workshops",

  "popup.afterSales.title": "After sales check",
  "popup.afterSales.text": "This customer has already deposit this model for repair, is this intervention is an after sales?",
  "popup.afterSales.ok": "Ok",

  "tile.qtyStock": "Quantity in stock:",
  "tile.delay": "Provider delay:",

  "error.403.session": "Login failed, please check username or password",
  "error.404.session": "Login failed, please consult the developer",
  "error.401.session": "Login failed, please check username or password",
  "error.wrongPassword.session": "wrong password",
  "error.401.auth": "Wrong username/password combination",
  "error.unhandled": "Common error",
  "error.400.wizard": "Bad Request (400)",
  "error.403.wizard": "Forbidden (403)",
  "error.404.wizard": "Not Found (404)",
  "error.400.upload": "Upload fail (400)",
  "error.403.upload": "Upload fail (403)",
  "error.404.upload": "Upload fail (404)",

  "error.500": "Internal Server Error (500)",
  "error.500.detail": "Internal Server Error (500)",
  "error.500.detail.action": "Internal Server Error (500)",
  "error.500.detail.action_parameter": "Internal Server Error (500)",
  "error.500.detail.customer": "Internal Server Error (500)",
  "error.500.detail.dashboard": "Internal Server Error (500)",
  "error.500.detail.model": "Internal Server Error (500)",
  "error.500.detail.profile": "Internal Server Error (500)",
  "error.500.detail.purchase": "Internal Server Error (500)",
  "error.500.detail.repair": "Internal Server Error (500)",
  "error.500.detail.report/csv": "Internal Server Error (500)",
  "error.500.detail.report/pdf": "Internal Server Error (500)",
  "error.500.detail.statistic": "Internal Server Error (500)",
  "error.500.detail.user": "Internal Server Error (500)",
  "error.500.detail.workshop": "Internal Server Error (500)",
  "error.500.list": "Internal Server Error (500)",
  "error.500.list.actions": "Internal Server Error (500)",
  "error.500.list.brand": "Internal Server Error (500)",
  "error.500.list.condition": "Internal Server Error (500)",
  "error.500.list.csv": "Internal Server Error (500)",
  "error.500.list.customer_type": "Internal Server Error (500)",
  "error.500.list.customers": "Internal Server Error (500)",
  "error.500.list.device_type": "Internal Server Error (500)",
  "error.500.list.discount_type": "Internal Server Error (500)",
  "error.500.list.identity": "Internal Server Error (500)",
  "error.500.list.messages": "Internal Server Error (500)",
  "error.500.list.models": "Internal Server Error (500)",
  "error.500.list.payment_type": "Internal Server Error (500)",
  "error.500.list.purchase_type": "Internal Server Error (500)",
  "error.500.list.purchases": "Internal Server Error (500)",
  "error.500.list.repairs": "Internal Server Error (500)",
  "error.500.list.reports": "Internal Server Error (500)",
  "error.500.list.role": "Internal Server Error (500)",
  "error.500.list.status": "Internal Server Error (500)",
  "error.500.list.stock_type":"Internal Server Error (500)",
  "error.500.list.users": "Internal Server Error (500)",
  "error.500.list.workshops": "Internal Server Error (500)",
  "error.500.list.repairs_pagination": "Internal Server Error (500)",
  "error.500.messages": "Internal Server Error (500)",

  "error.405": "Method not allowed (405)",
  "error.405.detail": "Method not allowed (405)",
  "error.405.detail.action": "Method not allowed (405)",
  "error.405.detail.action_parameter": "Method not allowed (405)",
  "error.405.detail.customer": "Method not allowed (405)",
  "error.405.detail.dashboard": "Method not allowed (405)",
  "error.405.detail.model": "Method not allowed (405)",
  "error.405.detail.profile": "Method not allowed (405)",
  "error.405.detail.purchase": "Method not allowed (405)",
  "error.405.detail.repair": "Method not allowed (405)",
  "error.405.detail.report/csv": "Method not allowed (405)",
  "error.405.detail.report/pdf": "Method not allowed (405)",
  "error.405.detail.statistic": "Method not allowed (405)",
  "error.405.detail.user": "Method not allowed (405)",
  "error.405.detail.workshop": "Method not allowed (405)",
  "error.405.list": "Method not allowed (405)",
  "error.405.list.actions": "Method not allowed (405)",
  "error.405.list.brand": "Method not allowed (405)",
  "error.405.list.condition": "Method not allowed (405)",
  "error.405.list.csv": "Method not allowed (405)",
  "error.405.list.customer_type": "Method not allowed (405)",
  "error.405.list.customers": "Method not allowed (405)",
  "error.405.list.device_type": "Method not allowed (405)",
  "error.405.list.discount_type": "Method not allowed (405)",
  "error.405.list.identity": "Method not allowed (405)",
  "error.405.list.messages": "Method not allowed (405)",
  "error.405.list.models": "Method not allowed (405)",
  "error.405.list.payment_type": "Method not allowed (405)",
  "error.405.list.purchase_type": "Method not allowed (405)",
  "error.405.list.purchases": "Method not allowed (405)",
  "error.405.list.repairs": "Method not allowed (405)",
  "error.405.list.reports": "Method not allowed (405)",
  "error.405.list.role": "Method not allowed (405)",
  "error.405.list.status": "Method not allowed (405)",
  "error.405.list.stock_type":"Method not allowed (405)",
  "error.405.list.users": "Method not allowed (405)",
  "error.405.list.workshops": "Method not allowed (405)",
  "error.405.list.repairs_pagination": "Method not allowed (405)",
  "error.405.messages": "Method not allowed (405)",

  "error.200": "Error while processing server response for",
  "error.200.detail": "Error while processing server response for",
  "error.200.detail.action": "Error while processing server response for",
  "error.200.detail.action_parameter": "Error while processing server response for",
  "error.200.detail.customer": "Error while processing server response for",
  "error.200.detail.dashboard": "Error while processing server response for",
  "error.200.detail.model": "Error while processing server response for",
  "error.200.detail.profile": "Error while processing server response for",
  "error.200.detail.purchase": "Error while processing server response for",
  "error.200.detail.repair": "Error while processing server response for",
  "error.200.detail.report/csv": "Error while processing server response for",
  "error.200.detail.report/pdf": "Error while processing server response for",
  "error.200.detail.statistic": "Error while processing server response for",
  "error.200.detail.user": "Error while processing server response for",
  "error.200.detail.workshop": "Error while processing server response for",
  "error.200.list": "Error while processing server response for",
  "error.200.list.actions": "Error while processing server response for",
  "error.200.list.brand": "Error while processing server response for",
  "error.200.list.condition": "Error while processing server response for",
  "error.200.list.csv": "Error while processing server response for",
  "error.200.list.customer_type": "Error while processing server response for",
  "error.200.list.customers": "Error while processing server response for",
  "error.200.list.device_type": "Error while processing server response for",
  "error.200.list.discount_type": "Error while processing server response for",
  "error.200.list.identity": "Error while processing server response for",
  "error.200.list.messages": "Error while processing server response for",
  "error.200.list.models": "Error while processing server response for",
  "error.200.list.payment_type": "Error while processing server response for",
  "error.200.list.purchase_type": "Error while processing server response for",
  "error.200.list.purchases": "Error while processing server response for",
  "error.200.list.repairs": "Error while processing server response for",
  "error.200.list.reports": "Error while processing server response for",
  "error.200.list.role": "Error while processing server response for",
  "error.200.list.status": "Error while processing server response for",
  "error.200.list.stock_type":"Error while processing server response for",
  "error.200.list.users": "Error while processing server response for",
  "error.200.list.workshops": "Error while processing server response for",
  "error.200.list.repairs_pagination":"Error while processing server response for",
  "error.200.messages": "Error while processing server response for"
}
